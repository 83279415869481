import d3 from "d3";
import _ from "lodash";
import moment from "moment";

import { iconPaths } from "./icons";

export const initialMapView = {
  zoom: 4,
  center: [33.65, -98.35]
};

// The types of raptors represented by tabs in the navigation
export const raptorTypes = [
  "ospreys",
  "golden-eagles",
  "hawks",
  "falcons",
  "vultures"
];

// Translates raptor types from app to the database
export const raptorTypeToDBSpecies = {
  hawks: "hawk",
  falcons: "falcon",
  "golden-eagles": "golden eagle",
  ospreys: "osprey",
  vultures: "vulture"
};

// the default application state / redux store
export const defaultState = {
  animating: false,
  browser: null,
  date: null,
  map: {},
  mpgRanch: {
    nests: [],
    ranchBoundary: {}
  },
  raptors: {
    ospreys: {
      birds: [],
      data: {},
      seasons: {},
      minMaxDates: null
    },
    "golden-eagles": {
      birds: [],
      data: {},
      seasons: {},
      minMaxDates: null
    },
    hawks: {
      birds: [],
      data: {},
      seasons: {},
      minMaxDates: null
    },
    falcons: {
      birds: [],
      data: {},
      seasons: {},
      minMaxDates: null
    },
    vultures: {
      birds: [],
      data: {},
      seasons: {},
      minMaxDates: null
    },
    add: {
      adding: false
    }
  },
  selectedTab: "map"
};

// data & state for golden eagles & ospreys are nested in store.raptors,
// the app only requires one at a time so this func returns the data for the
// router's `raptor` param
export function getSelectedRaptorSpeciesState(raptorsObj, raptorSpecies) {
  return raptorsObj[raptorSpecies] ? raptorsObj[raptorSpecies] : {};
}

export function birdHasDataNearDate(bird, startEndDates, date) {
  if (!startEndDates) return false;

  const birdStartEndDates = startEndDates[bird.id];
  if (!birdStartEndDates) return false;

  // Show birds even if it has been up to a month without data updates
  const start = moment(birdStartEndDates.start_date);
  const end = moment(birdStartEndDates.end_date).add(1, "month");

  return date.isBetween(start, end, null, "[]");
}

export function formatDate(date) {
  const yFormat = d3.time.format("%Y");
  const wFormat = d3.time.format("%W");
  const yVal = +yFormat(new Date(date));
  const wVal = +wFormat(new Date(date)) / 100;
  const yearWeek = yVal + wVal;

  return yearWeek;
}

export function formatDateSQL(timestamp) {
  const dateFormatSQLString = "%Y-%m-%d";
  const formatDateSQL = d3.time.format(dateFormatSQLString);
  return formatDateSQL(new Date(timestamp));
}

// get the name of a bird from its id
export const idToName = (state, id) => {
  return state
    .filter(bird => {
      return bird.id === id;
    })[0]
    .name.toLowerCase();
};

// get the colors of a bird from its id
export const idToGradient = (state, id, basemap) => {
  let bird = state.filter(bird => {
    return bird.id === id;
  })[0];

  if (basemap && bird.color && bird.color[basemap]) {
    return bird.color[basemap];
  } else {
    return { max: bird.max_color, min: bird.min_color };
  }
};

export const idToBaseColor = (state, id) => {
  let bird = state.filter(bird => {
    return bird.id === id;
  })[0];

  if (bird.color && bird.color.base) {
    return bird.color.base;
  } else {
    return bird.max;
  }
};

export function computeElementDimesions(ref) {
  let dimensions = {};

  if (!ref && typeof ref !== "object") return false;

  const target = ref;
  const rect = target.getBoundingClientRect();
  dimensions.width = Math.floor(rect.width);
  dimensions.height = Math.floor(rect.height);

  return dimensions;
}

// code credit: https://muffinresearch.co.uk/removing-leading-whitespace-in-es6-template-strings/
export function singleLineString(strings, ...values) {
  // Interweave the strings with the
  // substitution vars first.
  let output = "";
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  let lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace.
  return lines
    .map(line => {
      return line.replace(/^\s+/gm, "");
    })
    .join(" ")
    .trim();
}

export function isEmpty(object) {
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

// for nesting osprey data, code credit: https://gist.github.com/joyrexus/9837596
const nest = (seq, keys) => {
  if (!keys.length) return seq;
  let first = keys[0];
  let rest = keys.slice(1);
  return _.mapValues(_.groupBy(seq, first), function(value) {
    return nest(value, rest);
  });
};

function byId(d) {
  return d.id;
}

function byDateFormatted(d) {
  return d.date_formatted;
}

export function groupDataByIdAndDate(data) {
  // nests data by id then by formatted date
  return nest(data, [byId, byDateFormatted]);
}

export function dataMaxTimestamp(data) {
  // latest date from the data; for setting the date slider
  return _.maxBy(data, "timestamp").timestamp;
}

const makeSvg = (species, color) => {
  return `
    <svg version="1.1" id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;"
      xml:space="preserve">
      <path 
        fill="${color}"
        stroke-width="1px"
        stroke="#FFFFFF"
        stroke-opacity="0.3"
        d="${iconPaths[species]}"
      />
    </svg>
  `;
};

export const setRaptorIconPath = (raptorSpecies, color) => {
  const iconSvg = makeSvg(raptorSpecies, color);

  // uri encode # as %23 for Firefox & IE
  return encodeURI(`data:image/svg+xml,${iconSvg}`).replace(/#/g, "%23");
};

export const birdColors = [
  {
    min: "#e5f0f6",
    max: "#a6cee3",
    base: "#5787aa",
    positron: {
      min: "#7cabc7",
      max: "#9fc7dc"
    },
    satellite: {
      min: "#dee9ef",
      max: "#9fc7dc"
    }
  },
  {
    min: "#e8f2e9",
    max: "#acd7b6",
    base: "#3c9263",
    positron: {
      min: "#77b68d",
      max: "#a5d0af"
    },
    satellite: {
      min: "#a5d0af",
      max: "#e1ebe2"
    }
  },
  {
    min: "#eee9f3",
    max: "#cab2d6",
    base: "#8b5e90",
    positron: {
      min: "#ab88b5",
      max: "#c3abcf"
    },
    satellite: {
      min: "#c3abcf",
      max: "#e7e2ec"
    }
  },
  {
    min: "#fae2e1",
    max: "#fb9a99",
    base: "#e34942",
    positron: {
      min: "#ed6e6b",
      max: "#f49392"
    },
    satellite: {
      min: "#f49392",
      max: "#f3dbda"
    }
  },
  {
    min: "#fcebd6",
    max: "#fdbf6f",
    base: "#e17432",
    positron: {
      min: "#ed994d",
      max: "#f6b86f"
    },
    satellite: {
      min: "#f6b86f",
      max: "#f5e4cf"
    }
  }
];
