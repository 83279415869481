import fetch from "isomorphic-fetch";

import * as config from "./config";

export function disableBird(key, birdType, name, callback) {
  fetch(config.disable_bird_url, {
    method: "POST",
    body: JSON.stringify({ birdType, key, name }),
    headers: new Headers({ "Content-Type": "application/json" })
  })
    .then(response => response.json())
    .then(json => callback(null, json))
    .catch(error => {
      return callback("Error caught in disableBird:", error);
    });
}

export function submitBird(key, bird, callback) {
  fetch(config.submit_bird_url, {
    method: "POST",
    body: JSON.stringify({ bird, key }),
    headers: new Headers({ "Content-Type": "application/json" })
  })
    .then(response => response.json())
    .then(json => callback(null, json))
    .catch(error => {
      return callback("Error caught in submitBird:", error);
    });
}
