/* global _public */
// sets up the Leaflet Map, Osprey Markers, & CartoDB.JS layer

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import MiniMap from "leaflet-minimap";
import "./L.TileLayer.NoGap";

import { singleLineString, setRaptorIconPath } from "../../constants/app.js";
import tileLayers from "../../static/tileLayers.json";
import { breakpoints } from "../../scss/variables.json";

// the URL for Leaflet icons
const iconImagePath = "./img/";

let containerWidth;
let map;
let defaultBaseMap;
let positron;
let satellite;
let satelliteMini;
let miniMapSize = {};
let curBirdLocations;
let prevBirdLocations;
let prevBirdMarkers;
let nestLocations;
let ranchBoundary;
let ranchLabel;
let initialView;
let handleMoveEnd;
let icons = {};
let birdIconClick;
let zoomFlag = false;

function is_high_resolution_screen() {
  return window.devicePixelRatio > 1;
}

function createBaseMapLayers() {
  // basemap layers
  positron = L.tileLayer(
    is_high_resolution_screen()
      ? tileLayers.layers[2].url
      : tileLayers.layers[0].url,
    {
      zIndex: 0
    }
  );

  satellite = [
    L.tileLayer(tileLayers.layers[3].url, {
      opacity: 1,
      zIndex: 1
    }),

    L.tileLayer(tileLayers.layers[1].url, {
      opacity: 1,
      zIndex: 0
    })
  ];

  satelliteMini = L.tileLayer(tileLayers.layers[1].url, {
    zIndex: 1,
    opacity: 1
  });
}

function onMapMoveEnd() {
  const zoom = map.getZoom();
  const center = map.getCenter();
  // for setting url params for zoom & center
  if (handleMoveEnd) {
    handleMoveEnd({ zoom, center });
  }

  // hide / show the nest marker labels at specific zoom levels
  if (zoom >= 12) {
    ranchLabel.eachLayer(layer => {
      layer.openTooltip();
    });

    curBirdLocations.eachLayer(layer => {
      layer.setIcon(icons.lg[layer.feature.properties.id]);
      layer.closeTooltip();
    });

    zoomFlag = true;
  } else if (zoom < 12) {
    ranchLabel.eachLayer(layer => {
      layer.closeTooltip();
    });

    if (zoomFlag) {
      curBirdLocations.eachLayer(layer => {
        layer.setIcon(icons[layer.feature.properties.id]);
        layer.openTooltip();
      });

      zoomFlag = false;
    }
  }
}

function makeNestIcons() {
  // tell Leaflet where we are storing icon images
  L.Icon.Default.imagePath = iconImagePath;

  icons.nests = new L.Icon({
    shadowUrl: null,
    iconSize: [17, 17],
    shadowSize: null,
    iconAnchor: [8, 16],
    shadowAnchor: null,
    popupAnchor: [0, -8],
    iconUrl: require("../../img/icons-nest-location-color.png"),
    iconRetinaUrl: require("../../img/icons-nest-location-color@2x.png"),
    labelAnchor: [4, -8]
  });
}

/*
 * Creates the raptor icons
 */
export function makeIcons(birds, raptorSpecies) {
  // tell Leaflet where we are storing icon images
  L.Icon.Default.imagePath = iconImagePath;

  const OspreyIcon = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconSize: [35, 29],
      shadowSize: null,
      iconAnchor: [25, 20],
      shadowAnchor: null,
      popupAnchor: [-3, -15]
    }
  });

  const OspreyIconLg = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconSize: [73, 59],
      shadowSize: null,
      iconAnchor: [52, 42],
      shadowAnchor: null,
      popupAnchor: [-3, -30]
    }
  });

  if (birds) {
    icons.lg = {};

    birds.forEach(bird => {
      icons[bird.id] = new OspreyIcon({
        className: bird.name + "-icon",
        iconUrl: setRaptorIconPath(raptorSpecies, bird.max_color)
      });
      icons.lg[bird.id] = new OspreyIconLg({
        className: bird.name + "-icon",
        iconUrl: setRaptorIconPath(raptorSpecies, bird.max_color)
      });
    });
  }

  return icons;
}

function createNestLayer() {
  // osprey nest locations
  nestLocations = L.geoJson(null, {
    pointToLayer: (feature, latlng) => {
      let m = L.marker(latlng, {
        icon: icons.nests,
        opacity: 0
      })
        .bindTooltip(feature.properties.name, {
          className: "nest-icon-label",
          direction: "right",
          permanent: true,
          offset: [8, -8]
        })
        .on("add", () => {
          m.closeTooltip();
        });

      return m;
    }
  }).addTo(map);
}

function createBirdLocationLayers() {
  // styling for the stroke of previous week of data
  function prevBirdLocationsStyle(feature) {
    // need to get bird color here
    return {
      stroke: true,
      color: feature.properties.color,
      weight: 2,
      opacity: 0.6
    };
  }

  function pointToLayer(feature, latlng) {
    if (icons[feature.properties.id]) {
      const marker = L.marker(latlng, {
        icon: icons[feature.properties.id]
      })
        .bindTooltip(feature.properties.name, {
          className: `raptor-icon-label ${feature.properties.name}`,
          direction: "right",
          interactive: true,
          offset: [11, -12],
          permanent: true
        })
        .setZIndexOffset(1000);

      L.DomEvent.addListener(
        marker._tooltip,
        "click",
        () => birdIconClick(feature.properties.id),
        marker
      );

      return marker;
    }
  }

  // stores current bird locations
  curBirdLocations = L.geoJson(null, {
    pointToLayer
  })
    .addTo(map)
    .on("click", e => {
      birdIconClick(e.layer.feature.properties.id);
    });

  // stores previous bird locations
  prevBirdLocations = L.geoJson(null, {
    style: prevBirdLocationsStyle
  }).addTo(map);

  let renderer = L.canvas();

  prevBirdMarkers = L.geoJson(null, {
    pointToLayer: (feature, latlng) => {
      return L.circleMarker(latlng, {
        renderer,
        radius: 4,
        color: "black",
        weight: 1,
        fillColor: feature.properties.color,
        fillOpacity: 0.3,
        opacity: 0.3
      });
    }
  }).addTo(map);
}

function createRanchBoundaryLayer() {
  const ranchBoundaryStyle = {
    stroke: true,
    weight: 3,
    color: "#000",
    opacity: 0.4,
    fillColor: "#000",
    fillOpacity: 0.2,
    lineJoin: "miter",
    className: "mpg-ranch-boundary-svg"
  };

  // ranch property boundary
  ranchBoundary = L.geoJson(null, {
    style: ranchBoundaryStyle
  }).addTo(map);

  ranchLabel = L.geoJson(null, {
    pointToLayer: (feature, latlng) => {
      return L.circleMarker(latlng, {
        radius: 0,
        opacity: 0
      })
        .bindTooltip("MPG Ranch", {
          className: "mpg-ranch-boundary-label",
          direction: "right",
          offset: [-45, -25]
        })
        .openTooltip();
    }
  }).addTo(map);
}

function createMapAttribution() {
  // attributions for MPG Ranch, ESRI, CartoDB
  const mapAttribution = singleLineString`
    &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors,
    &copy; <a href="https://cartodb.com/attributions">CartoDB</a>,
    &copy; <a href="http://www.esri.com/">Esri</a>`;

  new L.control.attribution({
    prefix: "&copy; MPG Ranch",
    position: "bottomright"
  })
    .addAttribution(mapAttribution)
    .addTo(map);
}

function createMiniMap() {
  // set miniMap dimensions based on screen width
  if (containerWidth < breakpoints.width.xsmall) {
    miniMapSize.width = 100;
    miniMapSize.height = 75;
  } else {
    miniMapSize.width = 200;
    miniMapSize.height = 150;
  }

  new MiniMap(satelliteMini, {
    zoomLevelFixed: 1,
    // centerFixed: [36.244273,-99.843750],
    width: miniMapSize.width,
    height: miniMapSize.height,
    aimingRectOptions: {
      color: "#f6f6f6",
      weight: 2,
      opacity: "0.8",
      fillOpacity: 0,
      fill: false
    }
  }).addTo(map);
}

/*
 * Instantiates the Leaflet Map
 */
export function init(mapSelector) {
  createBaseMapLayers();

  map = L.map(mapSelector, {
    layers: defaultBaseMap === "positron" ? positron : satellite,
    minZoom: 3,
    maxZoom: 16,
    zoomControl: false,
    scrollWheelZoom: false,
    attributionControl: false,
    detectRetina: true
  });

  // set map center and zoom from initial state
  map.setView(initialView[0], initialView[1]);

  createMapAttribution();
  makeNestIcons();
  createBirdLocationLayers();
  createRanchBoundaryLayer();
  createNestLayer();
  createMiniMap();

  map.on({
    moveend: onMapMoveEnd
  });

  return {
    map,
    curBirdLocations,
    prevBirdLocations,
    prevBirdMarkers,
    nestLocations,
    ranchBoundary,
    ranchLabel,
    satellite,
    positron
  };
}

export function mount(selector) {
  if (!selector) {
    console.error("LMap needs a selector for map div");
    return _public;
  }
  return init(selector);
}

export function setInitialView(arr) {
  initialView = arr;
  return initialView;
}

export function onMoveEnd(func) {
  handleMoveEnd = func;
  return handleMoveEnd;
}

export function setBirdIconClick(func) {
  birdIconClick = func;
  return birdIconClick;
}

export function setContainerWidth(width) {
  containerWidth = width;
  return width;
}

export function setDefaultBaseMap(basemap) {
  defaultBaseMap = basemap;
  return defaultBaseMap;
}
