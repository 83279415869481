import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { selectTab } from "../actions/mobile_nav_actions";

class MobileNav extends Component {
  static propTypes = {
    selectTab: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {};
    this.tabs = ["raptors", "nests", "map"];
    this.renderTabs = this.renderTabs.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  componentWillMount() {
    this.setState({
      selected: this.props.selectedTab
    });
  }

  componentDidMount() {
    //
  }

  componentWillReceiveProps(nextProps) {
    //
  }

  handleTabClick(tab, e) {
    this.props.selectTab(tab);
    this.setState({
      selected: tab
    });
  }

  renderTabs() {
    return this.tabs.map(tab => {
      let tabClassName = "ui-box";

      if (tab === this.state.selected) {
        tabClassName += " selected";
      }

      return (
        <li
          key={tab}
          className={tabClassName}
          onClick={this.handleTabClick.bind(this, tab)}
        >
          {tab}
        </li>
      );
    });
  }

  render() {
    return (
      <div className="mobile-nav">
        <ul>{this.renderTabs()}</ul>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectTab
    },
    dispatch
  );
}

function mapStateToProps({ selectedTab }) {
  return { selectedTab };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNav);
