export const SELECT_BIRD = "SELECT_BIRD";
export const DESELECT_BIRD = "DESELECT_BIRD";
export const SELECT_ALL_BIRDS = "SELECT_ALL_BIRDS";
export const DESELECT_ALL_BIRDS = "DESELECT_ALL_BIRDS";
export const ZOOM_TO_BIRD = "ZOOM_TO_BIRD";
export const CLEAR_ZOOM_TO_BIRD = "CLEAR_ZOOM_TO_BIRD";
export const ZOOM_TO_NEST = "ZOOM_TO_NEST";
export const CLEAR_ZOOM_TO_NEST = "CLEAR_ZOOM_TO_NEST";
export const START_ADDING_BIRD = "START_ADDING_BIRD";
export const START_EDITING_BIRD = "START_EDITING_BIRD";
export const STOP_ADDING_BIRD = "STOP_ADDING_BIRD";

// for updating which birds are displayed
export function selectBird(bird, raptorSpecies) {
  return {
    type: SELECT_BIRD,
    raptorSpecies,
    bird
  };
}

export function deselectBird(bird, raptorSpecies) {
  return {
    type: DESELECT_BIRD,
    raptorSpecies,
    bird
  };
}

export function selectAllBirds(raptorSpecies, subtype) {
  return {
    type: SELECT_ALL_BIRDS,
    raptorSpecies,
    subtype
  };
}

export function deselectAllBirds(raptorSpecies, subtype) {
  return {
    type: DESELECT_ALL_BIRDS,
    raptorSpecies,
    subtype
  };
}

export function startAddingBird() {
  return {
    type: START_ADDING_BIRD
  };
}

export function startEditingBird(bird) {
  return {
    type: START_EDITING_BIRD,
    bird
  };
}

export function stopAddingBird() {
  return {
    type: STOP_ADDING_BIRD
  };
}
