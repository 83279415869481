import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { groupBy } from "lodash";

import { fetchRaptorNames } from "../../actions/async_actions.js";
import { getSelectedRaptorSpeciesState } from "../../constants/app";
import BirdToggleGroup from "./BirdToggleGroup.jsx";

class BirdToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.displayMobile = this.displayMobile.bind(this);
  }

  componentWillMount() {
    const { raptor, raptorState } = this.props;
    if (!raptorState.birds.length) {
      this.props.fetchRaptorNames(raptor);
    }
    this.setState({
      shouldDisplayMobile: false
    });
  }

  componentWillReceiveProps(nextProps) {
    const { raptor, raptorState } = nextProps;
    if (raptor !== this.props.raptor && !raptorState.birds.length) {
      this.props.fetchRaptorNames(raptor);
    }
  }

  displayMobile(nextProps) {
    const { browser, selectedTab } = nextProps;
    const component = this.refs.ospreyList;

    if (!browser.greaterThan.extraSmall && selectedTab === "ospreys") {
      component.classList.remove("hidden");
    } else if (!browser.greaterThan.extraSmall && selectedTab !== "ospreys") {
      component.classList.add("hidden");
    }
  }

  render() {
    const { raptors, raptor, raptorState, browser, selectedTab } = this.props;
    const birds = raptorState ? raptorState.birds : null;
    const seasons = raptorState ? raptorState.seasons : null;

    function createClassName(tab, browser) {
      if (tab === "raptors" && !browser.greaterThan.small) {
        return "bird-toggle";
      } else if (browser.greaterThan.small) {
        return "bird-toggle";
      } else {
        return "bird-toggle hidden";
      }
    }

    // Group birds by sub-type
    const groupedBirds = birds ? groupBy(birds, b => b.subtype) : null;

    return (
      <div ref="ospreyList" className={createClassName(selectedTab, browser)}>
        {groupedBirds && seasons
          ? Object.keys(groupedBirds).map(subtype => (
              <BirdToggleGroup
                key={`${raptor}-${subtype}`}
                birds={groupedBirds[subtype]}
                birdType={raptor}
                authentication={this.props.authentication}
                onEdit={this.props.onEdit}
                raptors={raptors}
                raptorState={raptorState}
                seasons={seasons}
                species={subtype}
              />
            ))
          : "Loading data..."}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchRaptorNames }, dispatch);
}

function mapStateToProps(
  { authentication, date, raptors, selectedTab, browser },
  ownProps
) {
  return {
    authentication,
    date,
    raptors,
    raptor: ownProps.raptor,
    raptorState: getSelectedRaptorSpeciesState(raptors, ownProps.raptor),
    selectedTab,
    browser
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BirdToggle);
