import * as asyncActions from "../actions/async_actions.js";
import * as birdActions from "../actions/bird_actions.js";

export default function(state = {}, action) {
  const { raptorSpecies } = action;
  const raptorState = state[raptorSpecies];
  const subtype = action.subtype === "null" ? null : action.subtype;

  switch (action.type) {
    case asyncActions.REQUEST_RAPTOR_NAMES:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          fetchingNames: true,
          birds: []
        }
      };

    case asyncActions.RECEIVE_RAPTOR_NAMES:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          fetchingNames: false,
          birds: action.names
        }
      };

    case asyncActions.REQUEST_MIN_MAX_DATES:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          fetchingMinMaxDates: true,
          minMaxDates: null
        }
      };

    case asyncActions.RECEIVE_MIN_MAX_DATES:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          fetchingMinMaxDates: false,
          minMaxDates: action.minMaxDates
        }
      };

    case asyncActions.REQUEST_START_END_DATES:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          fetchingStartEndDates: true,
          startEndDates: null
        }
      };

    case asyncActions.RECEIVE_START_END_DATES:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          fetchingStartEndDates: false,
          startEndDates: action.startEndDates
        }
      };

    case asyncActions.REQUEST_RAPTOR_LATITUDES:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          fetchingLatitudes: true,
          latitudes: null
        }
      };

    case asyncActions.RECEIVE_RAPTOR_LATITUDES:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          fetchingLatitudes: false,
          latitudes: action.latitudes
        }
      };

    case asyncActions.REQUEST_RAPTOR_DATA:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          fetchingData: true,
          data: {}
        }
      };

    case asyncActions.RECEIVE_RAPTOR_DATA:
      return {
        ...state,
        [action.raptorSpecies]: {
          ...raptorState,
          fetchingData: false,
          data: action.data
        }
      };

    case birdActions.SELECT_BIRD:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          birds: raptorState.birds.map(bird => {
            if (bird.id === action.bird.id) {
              return {
                ...bird,
                selected: true
              };
            }
            return bird;
          })
        }
      };

    case birdActions.DESELECT_BIRD:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          birds: raptorState.birds.map(bird => {
            if (bird.id === action.bird.id) {
              return {
                ...bird,
                selected: false
              };
            }
            return bird;
          })
        }
      };

    case birdActions.SELECT_ALL_BIRDS:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          birds: raptorState.birds.map((bird, index) => {
            return {
              ...bird,
              selected:
                (bird.subtype === subtype && bird.active) || bird.selected
            };
          })
        }
      };

    case birdActions.DESELECT_ALL_BIRDS:
      return {
        ...state,
        [raptorSpecies]: {
          ...raptorState,
          birds: raptorState.birds.map((bird, index) => {
            return {
              ...bird,
              selected: bird.subtype === subtype ? false : bird.selected
            };
          })
        }
      };

    // bird disable
    case asyncActions.BIRD_DISABLE:
      return {
        ...state,
        disabling: {
          disabling: true,
          birdType: action.birdType,
          name: action.name,
          error: null,
          lastDisabledBirdType: null,
          lastDisabledBirdName: null
        }
      };
    case asyncActions.BIRD_DISABLE_RESPONSE:
      const speciesData = state[action.birdType];
      speciesData.birds = speciesData.birds.filter(b => b.name !== action.name);
      return {
        ...state,
        [action.birdType]: speciesData,
        disabling: {
          disabling: false,
          birdType: null,
          name: null,
          error: null,
          lastDisabledBirdType: action.birdType,
          lastDisabledBirdName: action.name
        }
      };
    case asyncActions.BIRD_DISABLE_ERROR_RESPONSE:
      return {
        ...state,
        disabling: {
          disabling: false,
          error: action.error,
          birdType: null,
          name: null
        }
      };

    case birdActions.START_ADDING_BIRD:
      return {
        ...state,
        ...{
          add: {
            adding: true,
            submitting: false
          }
        }
      };
    case birdActions.START_EDITING_BIRD:
      return {
        ...state,
        ...{
          add: {
            adding: true,
            originalBird: action.bird,
            submitting: false
          }
        }
      };
    case birdActions.STOP_ADDING_BIRD:
      return {
        ...state,
        ...{
          add: {
            adding: false,
            submitting: false
          }
        }
      };

    case asyncActions.SUBMIT_BIRD:
      return {
        ...state,
        ...{
          add: {
            ...state.add,
            ...{ submitting: true }
          }
        }
      };
    case asyncActions.SUBMIT_BIRD_RESPONSE:
      return {
        ...state,
        ...{
          add: {
            adding: false,
            submitting: false
          }
        }
      };
    case asyncActions.SUBMIT_BIRD_ERROR_RESPONSE:
      return {
        ...state,
        ...{
          add: {
            ...state.add,
            ...{
              adding: true,
              error: action.error,
              submitting: false
            }
          }
        }
      };
    default:
      break;
  }

  return state;
}
