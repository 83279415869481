import React, { Component } from "react";
import classNames from "classnames";

import { raptorTypes, raptorTypeToDBSpecies } from "../constants/app";

function FormInput({ children, name, required = false }) {
  return (
    <div className="form-input">
      <label>
        <span className={classNames("form-input-label", { required })}>
          {name}
        </span>
        {children}
      </label>
    </div>
  );
}

// Translates raptor types from app to the database
export const raptorTypeToDisplay = {
  hawks: "hawk",
  falcons: "falcon",
  "golden-eagles": "eagle",
  ospreys: "osprey",
  vultures: "vulture"
};

export default class AddModal extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      bird: {
        cartodb_id: null,
        capture_age: null,
        capture_date: null,
        capture_location: null,
        description: null,
        displayName: null,
        image: null,
        lead_information: null,
        sensorType: "gps",
        sex: null,
        species: "osprey",
        subtype: null,
        transmitter: null,
        url: null,
        wingspan: null
      },
      errors: {}
    };

    if (props.originalBird) {
      const orig = props.originalBird;
      initialState.bird = {
        cartodb_id: orig.cartodb_id,
        capture_age: orig.capture_age,
        capture_date: orig.capture_date,
        capture_location: orig.capture_location,
        description: orig.description,
        displayName: orig.name,
        image: orig.image,
        lead_information: orig.lead_information,
        sensorType: orig.sensor_type,
        sex: orig.sex,
        species: orig.species,
        subtype: orig.subtype,
        transmitter: orig.id,
        url: orig.url,
        wingspan: null
      };
    }

    this.state = initialState;
  }

  fieldChanged(name, value) {
    this.setState({
      bird: {
        ...this.state.bird,
        ...{ [name]: value }
      }
    });
  }

  onError(fieldName, error) {
    this.setState({
      errors: {
        ...this.state.errors,
        ...{ [fieldName]: error }
      }
    });
  }

  clearError(fieldName) {
    this.setState({
      errors: {
        ...this.state.errors,
        ...{ [fieldName]: null }
      }
    });
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.submit(this.state.bird);
  }

  render() {
    const { close, isSubmitting } = this.props;
    const {
      cartodb_id,
      capture_age,
      capture_date,
      capture_location,
      description,
      displayName,
      image,
      lead_information,
      sex,
      sensorType,
      species,
      subtype,
      transmitter,
      url,
      wingspan
    } = this.state.bird;
    const { errors } = this.state;

    return (
      <div className="add-modal">
        <div className="add-modal-inner ui-box">
          <button onClick={close} className="close-button">
            &times;
          </button>
          <h2>{cartodb_id === null ? "Add" : "Update"} a bird</h2>
          <form onSubmit={this.onSubmit.bind(this)}>
            <div className="form-groups">
              <div className="form-group">
                <h3>Core information</h3>
                <div className="form-group-subhead">
                  These fields are required to collect data for the bird and
                  display it on the map
                </div>
                <FormInput name="Name" required={true}>
                  <input
                    type="text"
                    value={displayName}
                    onChange={e =>
                      this.fieldChanged("displayName", e.target.value)
                    }
                  />
                </FormInput>
                <FormInput name="Bird type" required={true}>
                  <select
                    value={species}
                    onChange={e => this.fieldChanged("species", e.target.value)}
                  >
                    {raptorTypes.map(r => (
                      <option value={raptorTypeToDBSpecies[r]}>
                        {raptorTypeToDisplay[r]}
                      </option>
                    ))}
                  </select>
                </FormInput>
                <FormInput name="Sub-type">
                  <input
                    type="text"
                    value={subtype}
                    onChange={e => this.fieldChanged("subtype", e.target.value)}
                  />
                </FormInput>
                <FormInput name="Animal ID" required={true}>
                  <input
                    type="text"
                    value={transmitter}
                    onChange={e =>
                      this.fieldChanged("transmitter", e.target.value)
                    }
                  />
                </FormInput>
                <FormInput name="Sensor type" required={true}>
                  <select
                    value={sensorType}
                    onChange={e =>
                      this.fieldChanged("sensorType", e.target.value)
                    }
                  >
                    <option value="gps">gps</option>
                    <option value="argos-doppler-shift">
                      argos-doppler-shift
                    </option>
                  </select>
                </FormInput>
              </div>

              <div className="form-group">
                <h3>Biographic information</h3>
                <div className="form-group-subhead">
                  These fields are optional and appear in the bird's popup
                </div>
                <FormInput name="Image">
                  <div className="form-input-field">
                    {image ? (
                      <img alt="Current bird thumbnail" src={image} />
                    ) : null}
                    {errors.image ? (
                      <span className="form-input-error">{errors.image}</span>
                    ) : null}
                    <input
                      type="file"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (!file) return;

                        // Reject with error if file is too large
                        if (file.size > 40000) {
                          this.onError(
                            "image",
                            "Image too large, please do not exceed 40KB"
                          );
                          return;
                        }

                        this.clearError("image");
                        const reader = new FileReader();
                        reader.onload = e =>
                          this.fieldChanged("image", e.target.result);
                        reader.readAsDataURL(file);
                      }}
                    />
                  </div>
                </FormInput>
                <FormInput name="Capture date">
                  <input
                    type="date"
                    value={capture_date}
                    onChange={e =>
                      this.fieldChanged("capture_date", e.target.value)
                    }
                  />
                </FormInput>
                <FormInput name="Capture location">
                  <input
                    type="text"
                    value={capture_location}
                    onChange={e =>
                      this.fieldChanged("capture_location", e.target.value)
                    }
                  />
                </FormInput>
                <FormInput name="Age on capture">
                  <input
                    type="number"
                    value={capture_age}
                    onChange={e =>
                      this.fieldChanged("capture_age", e.target.value)
                    }
                  />
                </FormInput>
                <FormInput name="Wingspan">
                  <input
                    type="text"
                    value={wingspan}
                    onChange={e =>
                      this.fieldChanged("wingspan", e.target.value)
                    }
                  />
                </FormInput>
                <FormInput name="Sex">
                  <select
                    value={sex}
                    onChange={e => this.fieldChanged("sex", e.target.value)}
                  >
                    <option value="female">female</option>
                    <option value="male">male</option>
                    <option value="unknown">unknown</option>
                  </select>
                </FormInput>
                <FormInput name="Lead test details">
                  <input
                    type="text"
                    value={lead_information}
                    onChange={e =>
                      this.fieldChanged("lead_information", e.target.value)
                    }
                  />
                </FormInput>
                <FormInput name="URL">
                  <input
                    type="text"
                    value={url}
                    onChange={e => this.fieldChanged("url", e.target.value)}
                  />
                </FormInput>
                <FormInput name="Description">
                  <textarea
                    value={description}
                    onChange={e =>
                      this.fieldChanged("description", e.target.value)
                    }
                  />
                </FormInput>
              </div>
            </div>

            <div className="form-actions">
              <button type="button" onClick={close}>
                Cancel
              </button>
              <button disabled={isSubmitting} type="submit">
                Save
              </button>
            </div>
            <div style={{ clear: "both" }} />
          </form>
        </div>
      </div>
    );
  }
}
