import { SELECT_MOBILE_NAV_TAB } from "../actions/mobile_nav_actions.js";

export default function(state = "golden-eagles", action) {
  switch (action.type) {
    case SELECT_MOBILE_NAV_TAB:
      return action.tab;
    default:
      break;
  }

  return state;
}
