import React from "react";
import { Router, Route, Redirect, useRouterHistory } from "react-router";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { defaultState } from "./constants/app.js";
import makeStore from "./store.js";

import App from "./views/App.jsx";

import { createHashHistory } from "history";

const appHistory = useRouterHistory(createHashHistory)({
  queryKey: false
});

// tmp create initial state here
const initialState = defaultState;

const store = makeStore(initialState);

render(
  <Provider store={store}>
    <Router history={appHistory}>
      <Route path="/:raptor" component={App} />
      <Redirect from="/" to="golden-eagles" />
    </Router>
  </Provider>,
  document.getElementById("root")
);
