import * as config from "./config.js";
import { fetchSql } from "./carto";

// The actual location data for a raptor species, note that the total number of rows
// are reduced using ST_Distance() and lag()
const raptors_query_join_reduced = `
  SELECT id, date_formatted, lat, lon, year, season, dist
  FROM (
    SELECT *,
    ST_Distance(
      a.the_geom_webmercator,
      lag(a.the_geom_webmercator)
      OVER (
        ORDER BY a.id, a.timestamp
      )
    ) AS dist
    FROM
    {{table_data}} a, {{table_names}} b
    WHERE a.id = b.transmitter
    AND a.lat != 0 AND a.lon != 0
    AND b.species = '{{species}}'
    ORDER BY a.id, a.timestamp desc
  ) _
  WHERE _.dist > 100 AND _.dist < 500000`;

export const getRaptorData = options =>
  fetchSql(raptors_query_join_reduced, "json", options);

const raptor_names_query = `
  SELECT display_name AS name, transmitter AS id, band_color, capture_age, to_char(capture_date, 'YYYY-MM-DD') AS capture_date, capture_location, cartodb_id, description, image, species, type AS subtype, lead_information, sensor_type, sex, transmitter_alt, wingspan, active, url
  FROM {{table_names}}
  WHERE species = '{{species}}'
  ORDER BY type, display_name`;

export const getRaptorNames = options =>
  fetchSql(raptor_names_query, "json", options);

// min / max date range for a raptor species
const min_max_date_query = `
  SELECT max(a.timestamp) AS maxDate, min(a.timestamp) AS minDate
  FROM {{table_data}} a, {{table_names}} b
  WHERE a.id = b.transmitter
    AND b.species = '{{species}}'`;

export const getMinMaxDates = options => {
  return fetchSql(min_max_date_query, "json", options);
};

// start & end dates for each raptor, for making Gant charts in timeline slider
// and for testing current selected date from slider against raptor location data
const raptor_start_end_dates = `
  SELECT a.id, max(a.timestamp) as end_date, min(a.timestamp) as start_date,
    max(a.date_formatted) as end_date_formatted,
    min(a.date_formatted) as start_date_formatted
  FROM {{table_data}} a, {{table_names}} b
  WHERE a.id = b.transmitter
    AND b.species = '{{species}}'
  GROUP BY id
  ORDER BY start_date`;

export const getStartEndDates = options =>
  fetchSql(raptor_start_end_dates, "json", options);

// Get an average latitude for each bird of a species for each week.
//
// The first select within the union gets all of the species data truncated to
// the week. The second select within the union gets the most recent data for
// each bird without truncating the date. The reasoning is that we want to make
// the date lines as long as we can, and sometimes truncating to the week makes
// the lines up to 6 days out of date. On shorter timelines this is more
// noticeable than longer ones.
const latitudes = `
  SELECT id, round(avg(lat)) as latitude, week
  FROM (
    (
      SELECT a.id, a.lat, date_trunc('week', a.date_time) as week
      FROM {{table_data}} a, {{table_names}} b
      WHERE a.id = b.transmitter
        AND b.species = '{{species}}'
    )
    UNION
    (
      WITH most_recent AS (
        SELECT a.id, max(a.date_time) AS date_time
        FROM movebank_output_combined a
        INNER JOIN raptor_names_combined b 
          ON a.id = b.transmitter AND b.species = '{{species}}'
        GROUP BY a.id
      )
      SELECT c.id, c.lat, c.date_time AS week
      FROM {{table_data}} c, most_recent
      WHERE c.id = most_recent.id
        AND c.date_time = most_recent.date_time
    )
  ) t
  group by id, week
  order by id, week`;

export const getLatitudes = options => fetchSql(latitudes, "json", options);

// nest location geojson layer
const osprey_nests = `
  SELECT cartodb_id, name, lat, lon
  FROM ${config.osprey_nest_table}`;

export const getNestLocations = () => fetchSql(osprey_nests, "json");

// mpg ranch boundary geojson layer
const ranch_boundary_query = "SELECT * FROM mpg_ranch_boundary";

export const getRanchBoundary = () => fetchSql(ranch_boundary_query, "geojson");
