import * as actions from "../actions/async_actions.js";

export const defaultState = {
  keyMatch: false,
  passwordMatch: false,
  roles: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.KEY_AUTHENTICATION_DATA_REQUEST:
      return {
        ...state,
        _fetchingKeyAuthentication: true,
        keyMatch: false,
        passwordMatch: false
      };
    case actions.KEY_AUTHENTICATION_DATA_RESPONSE:
      return {
        ...state,
        _fetchingKeyAuthentication: false,
        keyMatch: action.match,
        key: action.key,
        roles: action.roles
      };
    case actions.KEY_AUTHENTICATION_DATA_ERROR_RESPONSE:
      return {
        ...state,
        _fetchingKeyAuthentication: false,
        keyMatch: false,
        passwordMatch: false
      };
    case actions.PASSWORD_CHECK_DATA_REQUEST:
      return {
        ...state,
        _fetchingPasswordCheck: true,
        passwordMatch: false
      };
    case actions.PASSWORD_CHECK_DATA_RESPONSE:
      return {
        ...state,
        _fetchingPasswordCheck: false,
        passwordMatch: action.match
      };
    case actions.PASSWORD_CHECK_DATA_ERROR_RESPONSE:
      return {
        ...state,
        _fetchingPasswordCheck: false,
        passwordMatch: false
      };
    default:
      break;
  }

  return state;
};
