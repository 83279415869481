import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";

import * as asyncActions from "../actions/async_actions.js";
import {
  startAddingBird,
  startEditingBird,
  stopAddingBird
} from "../actions/bird_actions.js";

// local components
import { hasRole, ROLE_ADD_EDIT_BIRD } from "../auth/keys.js";
import AddModal from "../components/AddModal.jsx";
import LeafletMap from "../components/LeafletMap/LeafletMap.jsx";
import SelectRaptorSpecies from "../components/SelectRaptorSpecies.jsx";
import BirdToggle from "../components/BirdToggle/BirdToggle.jsx";
import BirdProfile from "../components/BirdProfile.jsx";
import RaptorNestLocations from "../components/RaptorNestLocations.jsx";
import DateSlider from "../components/DateSlider/DateSlider.jsx";
import MobileNav from "../components/MobileNav.jsx";

import mpgLogo from "../img/logos_MPG.png";
import raptorViewLogo from "../img/logos_RVRI.svg";

// config
import "../scss/main.scss";

// main app container
class App extends Component {
  static contextTypes = {
    router: React.PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      promptedForPassword: false
    };

    this.renderMobile = this.renderMobile.bind(this);
    this.renderDeskTop = this.renderDeskTop.bind(this);
  }

  componentWillMount() {
    const { browser } = this.props;

    this.setState({
      browserType: browser.width > 767 ? "desktop" : "mobile"
    });
  }

  componentDidMount() {
    const { actions, authentication, location } = this.props;

    if (location.query.key && !authentication._fetchingKeyAuthentication) {
      actions.fetchKeyAuthentication(location.query.key);
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { authentication, browser, raptors } = nextProps;

    if (browser && browser !== this.props.browser) {
      const newBrowserType = browser.width > 767 ? "desktop" : "mobile";

      if (this.state.browserType !== newBrowserType) {
        this.setState({ browserType: newBrowserType });
      }
    }

    if (!raptors.add.submitting && this.props.raptors.add.submitting) {
      this.props.actions.fetchRaptorNames(this.props.routeParams.raptor);
    }

    if (authentication.keyMatch && !authentication.passwordMatch) {
      this.promptForPassword(nextProps);
    }

    if (
      !authentication._fetchingPasswordCheck &&
      this.props.authentication._fetchingPasswordCheck &&
      !authentication.passwordMatch
    ) {
      alert("Password did not match. Refresh to attempt again.");
    }
  }

  promptForPassword(props) {
    const { actions, authentication } = props;
    if (
      !authentication._fetchingPasswordCheck &&
      !this.state.promptedForPassword
    ) {
      this.setState({ promptedForPassword: true });
      const password = prompt(
        "Please enter the password to enable administrative functions."
      );
      actions.fetchPasswordCheck(authentication.key, password);
    }
  }

  renderMobile(raptor) {
    return (
      <div className="container full-height mobile">
        <div className="row u-full-width full-height">
          <div className="logo">
            <header />
          </div>
          <MobileNav raptor={raptor} />
          <SelectRaptorSpecies
            browser={this.props.browser}
            raptor={raptor}
            tab={this.props.selectedTab}
          />
          <BirdToggle raptor={raptor} />
          <RaptorNestLocations raptor={raptor} />
          <LeafletMap raptor={raptor} />
          <DateSlider raptor={raptor} />
          <BirdProfile raptor={raptor} />
        </div>
      </div>
    );
  }

  renderDeskTop(raptor) {
    const { authentication, raptors } = this.props;
    const adding = raptors && raptors.add.adding;
    const {
      startAddingBird,
      startEditingBird,
      stopAddingBird
    } = this.props.actions;

    return (
      <div className="container full-height">
        <div className="row u-full-width full-height">
          <div className="three columns menu-column full-height left">
            <div
              className={classNames("row", "u-full-width", {
                "menu-height": raptor === "ospreys",
                "menu-full-height": raptor !== "ospreys"
              })}
            >
              <div className="logo logo-left">
                <header />
              </div>
              <SelectRaptorSpecies
                browser={this.props.browser}
                raptor={raptor}
                tab={this.props.selectedTab}
              />
              <div
                className="ui-box"
                style={{ height: "calc(100% - 115px)", overflow: "auto" }}
              >
                <BirdToggle raptor={raptor} onEdit={startEditingBird} />
                {hasRole(authentication, ROLE_ADD_EDIT_BIRD) ? (
                  <button onClick={startAddingBird}>Add a bird</button>
                ) : null}
              </div>
            </div>
            {raptor === "ospreys" ? (
              <div className="row u-full-width one-fifth-height">
                <RaptorNestLocations raptor={raptor} />
              </div>
            ) : null}
            <div className="row u-full-width logos">
              <div className="logo logo-right">
                <div className="logo-raptor-view">
                  <a
                    href="http://www.raptorview.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img alt="Raptor View" src={raptorViewLogo} />
                  </a>
                </div>
                <div className="logo-mpg-ranch">
                  <a
                    href="http://portal.mpgranch.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img alt="MPG Ranch" src={mpgLogo} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="nine columns map-column full-height right">
            <div className="row four-fifths-height u-full-width">
              <div style={{ height: "100%" }} className="row u-full-width">
                <LeafletMap raptor={raptor} />
              </div>
            </div>
            <div className="row u-full-width one-fifth-height">
              <DateSlider raptor={raptor} />
            </div>
          </div>
        </div>

        {adding ? (
          <AddModal
            originalBird={this.props.raptors.add.originalBird}
            close={stopAddingBird}
            isSubmitting={this.props.raptors.add.submitting}
            submit={bird =>
              this.props.actions.initiateSubmitBird(authentication.key, bird)
            }
          />
        ) : null}
      </div>
    );
  }

  render() {
    const { browser, params } = this.props;
    const toRender =
      browser.width > 767 ? this.renderDeskTop : this.renderMobile;
    let raptor = params.raptor;

    return toRender(raptor);
  }
}

function mapStateToProps({ browser, authentication, raptors, selectedTab }) {
  return { browser, authentication, raptors, selectedTab };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...asyncActions,
    ...{
      startAddingBird,
      startEditingBird,
      stopAddingBird
    }
  };

  return {
    actions: {
      ...bindActionCreators(actions, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
