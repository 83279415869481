import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { zoomTo } from "../actions/map_actions.js";
import { live_data_map_url } from "../constants/config.js";

// currenly only renders nest locations for Ospreys as MPG Ranch doesn't have
// any nest locations for Golden Eagles...
const OspreyNestLocations = props => {
  function zoomToNest(id, e) {
    props.zoomTo(id);
  }

  function renderNestList(nest) {
    if (!nest) return;
    const { name, cartodb_id } = nest;
    let nestCamLink =
      cartodb_id === 1
        ? ""
        : cartodb_id === 2
        ? `${live_data_map_url}?livecam=Osprey%20Nest%20North&livecams=true`
        : cartodb_id === 3
        ? `${live_data_map_url}?livecam=Osprey%20Nest%20South&livecams=true`
        : null;
    return (
      <li key={cartodb_id}>
        <span className="nest-name">{name}</span>
        {cartodb_id !== 1 ? (
          <span className="nest-cam-link">
            <a target="_blank" rel="noopener noreferrer" href={nestCamLink}>
              View Nest
            </a>
          </span>
        ) : null}
        <span
          className="locate-btn"
          onClick={zoomToNest.bind(this, cartodb_id)}
        />
      </li>
    );
  }

  const { mpgRanch, browser, selectedTab } = props;
  const nests = mpgRanch ? mpgRanch.nests : null;

  // initial className for mobile
  const className =
    (selectedTab === "nests" && !browser.greaterThan.extraSmall) ||
    (selectedTab === "nests" && !browser.greaterThan.small) ||
    browser.greaterThan.small
      ? "nest-locations ui-box"
      : "nest-locations ui-box hidden";

  return (
    <div className={className}>
      <h2 className="uppercase normal">Osprey Nest Locations</h2>
      <ul className="nest-location-list">
        {nests ? nests.map(renderNestList) : ""}
      </ul>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ zoomTo }, dispatch);
}

function mapStateToProps({ mpgRanch, browser, selectedTab }, ownProps) {
  return { mpgRanch, browser, selectedTab };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OspreyNestLocations);
