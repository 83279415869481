import React from "react";
import { Link } from "react-router";

const RaptorLink = ({ raptor, children }) => (
  <Link to={raptor} activeClassName="active" className="raptor-link">
    {children}
  </Link>
);

export default RaptorLink;
