import { PLAY_ANIMATION, STOP_ANIMATION } from "../actions/slider_actions.js";

export default function(state = false, action) {
  switch (action.type) {
    case PLAY_ANIMATION:
      return true;

    case STOP_ANIMATION:
      return false;
    default:
      break;
  }

  return state;
}
