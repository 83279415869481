import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  selectBird,
  deselectBird,
  selectAllBirds,
  deselectAllBirds
} from "../../actions/bird_actions.js";
import BirdToggleItem from "./BirdToggleItem.jsx";

class BirdToggleGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showArchive: false
    };
  }

  handleToggleArchive() {
    const { birdType, birds } = this.props;
    const showArchive = !this.state.showArchive;
    this.setState({ showArchive });

    const archiveBirds = birds.filter(bird => !bird.active);

    if (!showArchive) {
      archiveBirds.forEach(bird => this.props.deselectBird(bird, birdType));
    } else {
      archiveBirds.forEach(bird => this.props.selectBird(bird, birdType));
    }
  }

  render() {
    const { birds, birdType, species } = this.props;
    const { showArchive } = this.state;
    const archivedBirds = birds.filter(b => !b.active);

    return (
      <div key={`species-${species}`} className="bird-species-group">
        <div className="bird-species-name">
          {species !== "null" ? `${species}s` : birdType}
        </div>
        <div className="species-select-buttons">
          <span className="species-select-buttons-label">select</span>
          <button onClick={() => this.props.selectAllBirds(birdType, species)}>
            all
          </button>
          /
          <button
            onClick={() => this.props.deselectAllBirds(birdType, species)}
          >
            none
          </button>
        </div>
        <ul>
          {birds
            .filter(b => b.active)
            .map(bird => (
              <BirdToggleItem
                key={bird.id}
                bird={bird}
                birdType={birdType}
                onEdit={this.props.onEdit}
                raptors={this.props.raptors}
                seasons={this.props.seasons}
              />
            ))}
        </ul>
        {archivedBirds.length ? (
          <div className="show-archive-button-wrapper">
            <button
              onClick={this.handleToggleArchive.bind(this)}
              className="show-archive-button"
            >
              {showArchive ? "hide" : "show"} archive
            </button>
          </div>
        ) : null}
        {archivedBirds.length && showArchive ? (
          <div className="archived-birds-section">
            {species !== "null" ? (
              <div className="bird-species-name">Archived {species}s</div>
            ) : null}
            <ul>
              {archivedBirds.map(bird => (
                <BirdToggleItem
                  key={bird.id}
                  bird={bird}
                  birdType={birdType}
                  onEdit={this.props.onEdit}
                  raptors={this.props.raptors}
                  seasons={this.props.seasons}
                />
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectBird,
      deselectBird,
      selectAllBirds,
      deselectAllBirds
    },
    dispatch
  );
}

function mapStateToProps({ raptors }, ownProps) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BirdToggleGroup);
