import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { birdIconClicked, birdIconClosed } from "../actions/map_actions.js";
import { getSelectedRaptorSpeciesState } from "../constants/app";

class BirdProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setProfileContent = this.setProfileContent.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.getBird = this.getBird.bind(this);
  }

  componentWillMount() {
    this.setState({
      selectedBird: null
    });
  }

  componentWillReceiveProps(nextProps) {
    const { map, raptor } = nextProps;

    if (raptor !== this.props.raptor) {
      this.closePopUp();
    }

    if (map.clickedBirdId !== this.props.map.clickedBirdId) {
      this.setProfileContent(map.clickedBirdId);
    } else if (!map.clickedBirdId) {
      this.setState({ selectedBird: null });
    }
  }

  setProfileContent(id) {
    this.setState({ selectedBird: id });
  }

  closePopUp() {
    this.props.birdIconClosed();
    this.setState({ selectedBird: null });
  }

  getBird(id) {
    let toReturn;

    this.props.raptorState.birds.forEach(bird => {
      if (bird.id === id) {
        toReturn = bird;
      }
    });

    return toReturn;
  }

  getBirdStats(info, startEndDates) {
    const stats = [];
    if (startEndDates) {
      stats.push({
        label: "Last seen",
        value: moment(new Date(startEndDates.end_date)).format(
          "YYYY-DD-MM, hh:mm:ss UTC"
        )
      });
    }
    if (info.band_color) {
      stats.push({ label: "Band Color", value: info.band_color });
    }
    if (info.capture_age) {
      stats.push({ label: "Age on Capture", value: info.capture_age });
    }
    if (info.capture_date) {
      stats.push({ label: "Date of Capture", value: info.capture_date });
    }
    if (info.capture_location) {
      stats.push({ label: "Capture Location", value: info.capture_location });
    }
    if (info.lead_information) {
      stats.push({ label: "Lead", value: info.lead_information });
    }
    if (info.sex && info.sex !== "unknown") {
      stats.push({ label: "Sex", value: info.sex });
    }
    if (info.wingspan) {
      stats.push({ label: "Wingspan", value: info.wingspan });
    }
    return stats;
  }

  render() {
    const birdInfo = this.state.selectedBird
      ? this.getBird(this.state.selectedBird)
      : {};
    const { description, image, name, url } = birdInfo;
    const { startEndDates } = this.props.raptors[this.props.raptor];

    const birdStartEndDates =
      startEndDates && birdInfo.id ? startEndDates[birdInfo.id] : null;
    const stats = this.getBirdStats(birdInfo, birdStartEndDates);

    return (
      <div
        className="bird-profile"
        style={{ display: this.state.selectedBird ? "block" : "none" }}
      >
        <div className="close">
          <span onClick={this.closePopUp}>×</span>
        </div>
        <div className="bird-profile-content">
          {image ? (
            <div
              className="bird-photo"
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
          <div className="bird-about-text">
            <div className="bird-name">{name}</div>
            {stats.map(info => (
              <div className="bird-structured-info" key={info.label}>
                <div className="structured-info-label">{info.label}</div>
                <div className="structured-info-value">{info.value}</div>
              </div>
            ))}
            <div style={{ clear: "both" }} />
            <div className="bird-description-text">
              <p>{description}</p>
            </div>
            {url ? (
              <div className="bird-link">
                <a href={url} target="_blank" rel="noopener noreferrer">
                  Learn more
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      birdIconClicked,
      birdIconClosed
    },
    dispatch
  );
}

function mapStateToProps({ map, raptors, selectedTab }, ownProps) {
  return {
    map,
    raptors,
    selectedTab,
    raptorState: getSelectedRaptorSpeciesState(raptors, ownProps.raptor)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BirdProfile);
