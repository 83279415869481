import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import moment from "moment";

import { initiateDisableBird } from "../../actions/async_actions.js";
import { selectBird, deselectBird } from "../../actions/bird_actions.js";
import { birdIconClicked, zoomTo } from "../../actions/map_actions.js";
import {
  hasRole,
  ROLE_ADD_EDIT_BIRD,
  ROLE_DELETE_BIRD
} from "../../auth/keys.js";
import {
  birdHasDataNearDate,
  getSelectedRaptorSpeciesState,
  idToGradient
} from "../../constants/app";

class BirdToggleItem extends Component {
  addRemoveBird(bird, e) {
    const { birdType } = this.props;
    if (e.target.checked) {
      this.props.selectBird(bird, birdType);
    } else {
      this.props.deselectBird(bird, birdType);
    }
  }

  zoomToBird(bird, e) {
    this.props.birdIconClicked(bird);
    this.props.zoomTo(bird);
  }

  render() {
    const {
      date,
      initiateDisableBird,
      authentication,
      onEdit,
      bird,
      birdType,
      raptors
    } = this.props;
    if (!bird || !birdType) return;
    const { startEndDates } = raptors[birdType];
    const { id, active, name, selected } = bird;
    const disabling =
      raptors.disabling &&
      raptors.disabling.disabling &&
      raptors.disabling.name === name &&
      raptors.disabling.birdType === birdType;

    const willAppearOnMap = birdHasDataNearDate(
      bird,
      startEndDates,
      moment(date)
    );

    return (
      <li
        key={id}
        className={classNames("bird-toggle-item", {
          active,
          archived: !active
        })}
      >
        <div style={{ position: "relative" }}>
          <label key={id}>
            <input
              type="checkbox"
              value={id}
              checked={selected}
              onChange={this.addRemoveBird.bind(this, { id, name })}
            />
            <span
              className="bird-swatch"
              style={{
                backgroundColor: idToGradient(raptors[birdType].birds, id).max
              }}
            ></span>
            <span className="bird-name">{name}</span>
          </label>
          <div className="bird-actions">
            <span>
              {selected && hasRole(authentication, ROLE_ADD_EDIT_BIRD) ? (
                <button
                  className="edit-bird-button"
                  onClick={() => onEdit({ ...bird, ...{ birdType: birdType } })}
                >
                  edit
                </button>
              ) : null}
              {selected && hasRole(authentication, ROLE_DELETE_BIRD) ? (
                <button
                  className="delete-bird-button"
                  onClick={() => {
                    if (!disabling) {
                      this.addRemoveBird(bird, { target: { checked: false } });
                      initiateDisableBird(authentication.key, birdType, name);
                    }
                  }}
                  disabled={disabling}
                >
                  archive
                </button>
              ) : null}
            </span>

            {willAppearOnMap ? (
              <span
                className={selected ? "locate-btn" : "locate-btn disabled"}
                style={
                  selected ? { display: "inline-block" } : { display: "none" }
                }
                onClick={this.zoomToBird.bind(this, id)}
              />
            ) : null}
          </div>
        </div>
      </li>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectBird,
      birdIconClicked,
      deselectBird,
      initiateDisableBird,
      zoomTo
    },
    dispatch
  );
}

function mapStateToProps({ authentication, date, raptors }, ownProps) {
  return {
    authentication,
    date,
    raptors,
    raptor: ownProps.raptor,
    raptorState: getSelectedRaptorSpeciesState(raptors, ownProps.raptor)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BirdToggleItem);
