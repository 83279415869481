import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEqual, isEmpty } from "lodash";

import {
  updateSelectedDate,
  playAnimation,
  stopAnimation,
  updateBounds
} from "../../actions/slider_actions.js";
import {
  fetchLatitudes,
  fetchMinMaxDates,
  fetchStartEndDates
} from "../../actions/async_actions.js";
import {
  computeElementDimesions,
  getSelectedRaptorSpeciesState
} from "../../constants/app.js";
import D3Slider from "./d3_slider.js";

class DateSlider extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {};
    this.firstLoad = true;

    this.shouldInitSlider = this.shouldInitSlider.bind(this);
    this.initSlider = this.initSlider.bind(this);
    this.resizeSlider = this.resizeSlider.bind(this);
    this.onDateSliderChange = this.onDateSliderChange.bind(this);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.toggleAnimation = this.toggleAnimation.bind(this);
    this.updateSliderProps = this.updateSliderProps.bind(this);
  }

  componentWillMount() {
    const { raptor } = this.props;
    this.props.fetchLatitudes(raptor);
    this.props.fetchMinMaxDates(raptor);
    this.props.fetchStartEndDates(raptor);
  }

  componentDidMount() {
    //
  }

  componentWillReceiveProps(nextProps) {
    const { animating, date, browser, raptorState, raptor } = nextProps;
    const { birds, latitudes, startEndDates, minMaxDates } = raptorState;

    if (!this.slider && raptor === this.props.raptor && !isEmpty(minMaxDates)) {
      this.initSlider(minMaxDates);
    }

    if (this.slider && raptor !== this.props.raptor) {
      // on raptor species toggle redraw the slider
      this.refs.sliderContainer.innerHTML = "";
      this.slider = null;
      this.firstLoad = true;
      if (isEmpty(minMaxDates)) this.props.fetchMinMaxDates(raptor);
      if (isEmpty(startEndDates)) this.props.fetchStartEndDates(raptor);
      if (isEmpty(latitudes)) this.props.fetchLatitudes(raptor);
      // if data for both species has already been fetched, then create the slider
      if (!isEmpty(minMaxDates) && !isEmpty(latitudes)) {
        this.initSlider(minMaxDates);
        this.onBirdToggle(raptor, birds, latitudes);
      }
    }

    if (this.slider) {
      if (animating !== this.props.animating) {
        // ie: the user hit the play / pause button
        this.toggleAnimation(nextProps);
      }

      if (date !== this.props.date) {
        // ie: the user moved the handle and the current selected date changed
        this.updateSliderProps(nextProps);
      }

      if (browser !== this.props.browser) {
        const wh = computeElementDimesions(this.refs.sliderContainer);
        this.resizeSlider(wh);
      }

      // when app first loads make sure any birds that are already selected have gant chart lines drawn
      if (
        this.firstLoad &&
        !isEmpty(latitudes) &&
        !isEmpty(minMaxDates) &&
        birds.length
      ) {
        this.onBirdToggle(raptor, birds, latitudes);
        this.firstLoad = false;
      }

      // if a bird is toggled, update the gant chart
      if (
        !isEmpty(latitudes) &&
        birds.length &&
        !isEqual(birds, this.props.raptorState.birds)
      ) {
        this.onBirdToggle(raptor, birds, latitudes);
      }
    }
  }

  componentDidUpdate() {
    //
  }

  componentWillUnmount() {
    //
  }

  handleBtnClick(e) {
    const { animating } = this.props;

    if (animating) {
      this.props.stopAnimation();
    } else {
      this.props.playAnimation();
    }
  }

  onDateSliderChange = timestamp => {
    const { updateSelectedDate } = this.props;
    if (typeof updateSelectedDate === "function") {
      updateSelectedDate(timestamp);
    }
  };

  onDateBoundsChange = (start, end) => {
    const { updateBounds } = this.props;
    if (typeof updateBounds === "function") {
      updateBounds(start, end);
    }
  };

  onBirdToggle = (raptorSpecies, birds, latitudes) => {
    this.slider.setLatitudes(raptorSpecies, birds, latitudes);
  };

  toggleAnimation(nextProps) {
    const { animating } = nextProps;
    this.slider.animate(animating);
  }

  updateSliderProps(nextProps) {
    this.slider.updateSliderProps(nextProps);
  }

  shouldInitSlider() {
    if (this.slider) {
      return false;
    }

    return true;
  }

  initSlider(minMaxDates) {
    const wh = computeElementDimesions(this.refs.sliderContainer);
    this.slider = new D3Slider();
    this.slider.setMinMaxDates(minMaxDates);
    this.slider.setContainerWidth(this.props.browser.width);
    this.slider.updateState(this.onDateSliderChange);
    this.slider.updateBounds(this.onDateBoundsChange);
    this.slider.mount(this.refs.sliderContainer, wh);
  }

  resizeSlider(wh) {
    this.slider.resizeSlider(wh);
  }

  render() {
    const { browser, selectedTab } = this.props;

    return (
      <div
        className={classNames("date-slider", "ui-box", {
          "hidden-sneaky": !browser.greaterThan.small && selectedTab !== "map"
        })}
      >
        <div className="left">
          <div className="slider-title">
            <h2 className="normal uppercase">Timeline</h2>
          </div>
          <div className="slider-buttons">
            <button
              onClick={this.handleBtnClick.bind(this)}
              className={classNames("slider-animation", {
                pause: this.props.animating,
                play: !this.props.animating
              })}
            />
          </div>
        </div>
        <div className="right">
          <div ref="sliderContainer" className="slider-container" />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSelectedDate,
      playAnimation,
      stopAnimation,
      fetchLatitudes,
      fetchMinMaxDates,
      fetchStartEndDates,
      updateBounds
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ...state,
    raptorState: getSelectedRaptorSpeciesState(state.raptors, ownProps.raptor),
    raptor: ownProps.raptor
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateSlider);
