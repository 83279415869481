import { UPDATE_BOUNDS } from "../actions/slider_actions.js";

export default function(state = [0, 0], action) {
  switch (action.type) {
    case UPDATE_BOUNDS:
      return [action.start, action.end];
    default:
      break;
  }

  return state;
}
