import { disableBird, submitBird } from "../constants/birdService";
import {
  getRaptorData,
  getRaptorNames,
  getLatitudes,
  getNestLocations,
  getMinMaxDates,
  getStartEndDates,
  getRanchBoundary
} from "../constants/getRaptorData.js";

import {
  birdColors,
  groupDataByIdAndDate,
  raptorTypeToDBSpecies
} from "../constants/app.js";

import * as config from "../constants/config.js";

import { keyBy } from "lodash";

export const REQUEST_RAPTOR_DATA = "REQUEST_RAPTOR_DATA";
export const RECEIVE_RAPTOR_DATA = "RECEIVE_RAPTOR_DATA";
export const REQUEST_RAPTOR_NAMES = "REQUEST_RAPTOR_NAMES";
export const RECEIVE_RAPTOR_NAMES = "RECEIVE_RAPTOR_NAMES";
export const REQUEST_MIN_MAX_DATES = "REQUEST_MIN_MAX_DATES";
export const RECEIVE_MIN_MAX_DATES = "RECEIVE_MIN_MAX_DATES";
export const REQUEST_NEST_LOCATIONS = "REQUEST_NEST_LOCATIONS";
export const RECEIVE_NEST_LOCATIONS = "RECEIVE_NEST_LOCATIONS";
export const REQUEST_RANCH_BOUNDARY = "REQUEST_RANCH_BOUNDARY";
export const RECEIVE_RANCH_BOUNDARY = "RECEIVE_RANCH_BOUNDARY";
export const REQUEST_YEAR_SEASONS = "REQUEST_YEAR_SEASONS";
export const RECEIVE_YEAR_SEASONS = "YEAR_SEASONS";
export const REQUEST_START_END_DATES = "REQUEST_START_END_DATES";
export const RECEIVE_START_END_DATES = "RECEIVE_START_END_DATES";
export const REQUEST_RAPTOR_LATITUDES = "REQUEST_RAPTOR_LATITUDES";
export const RECEIVE_RAPTOR_LATITUDES = "RECEIVE_RAPTOR_LATITUDES";
export const KEY_AUTHENTICATION_DATA_REQUEST =
  "KEY_AUTHENTICATION_DATA_REQUEST";
export const KEY_AUTHENTICATION_DATA_RESPONSE =
  "KEY_AUTHENTICATION_DATA_RESPONSE";
export const KEY_AUTHENTICATION_DATA_ERROR_RESPONSE =
  "KEY_AUTHENTICATION_DATA_ERROR_RESPONSE";
export const PASSWORD_CHECK_DATA_REQUEST = "PASSWORD_CHECK_DATA_REQUEST";
export const PASSWORD_CHECK_DATA_RESPONSE = "PASSWORD_CHECK_DATA_RESPONSE";
export const PASSWORD_CHECK_DATA_ERROR_RESPONSE =
  "PASSWORD_CHECK_DATA_ERROR_RESPONSE";
export const BIRD_DISABLE = "BIRD_DISABLE";
export const BIRD_DISABLE_RESPONSE = "BIRD_DISABLE_RESPONSE";
export const BIRD_DISABLE_ERROR_RESPONSE = "BIRD_DISABLE_ERROR_RESPONSE";
export const SUBMIT_BIRD = "SUBMIT_BIRD";
export const SUBMIT_BIRD_RESPONSE = "SUBMIT_BIRD_RESPONSE";
export const SUBMIT_BIRD_ERROR_RESPONSE = "SUBMIT_BIRD_ERROR_RESPONSE";

const getQueryOptions = raptorSpecies => {
  return {
    species: raptorTypeToDBSpecies[raptorSpecies],
    table_data: config.table_movebank_data,
    table_names: config.table_names
  };
};

// each raptor's min and max timestamp, used for drawing gant lines over date range slider
const requestStartEndDates = raptorSpecies => ({
  type: REQUEST_START_END_DATES,
  raptorSpecies,
  startEndDates: null
});

const receiveStartEndDates = (raptorSpecies, startEndDates) => ({
  type: RECEIVE_START_END_DATES,
  raptorSpecies,
  startEndDates
});

export const fetchStartEndDates = raptorSpecies => {
  const options = getQueryOptions(raptorSpecies);

  return dispatch => {
    dispatch(requestStartEndDates(raptorSpecies));

    return getStartEndDates(options)
      .then(results => {
        const startEndDates = keyBy(results.rows, "id");
        dispatch(receiveStartEndDates(raptorSpecies, startEndDates));
      })
      .catch(err => {
        console.warn(err);
        return dispatch(receiveStartEndDates(raptorSpecies, null));
      });
  };
};

const requestLatitudes = raptorSpecies => ({
  type: REQUEST_RAPTOR_LATITUDES,
  raptorSpecies,
  latitudes: null
});

const receiveLatitudes = (raptorSpecies, latitudes) => ({
  type: RECEIVE_RAPTOR_LATITUDES,
  raptorSpecies,
  latitudes
});

export const fetchLatitudes = raptorSpecies => {
  const options = getQueryOptions(raptorSpecies);

  return dispatch => {
    dispatch(requestLatitudes(raptorSpecies));

    return getLatitudes(options)
      .then(results => {
        dispatch(
          receiveLatitudes(
            raptorSpecies,
            results.rows.map(r => {
              return Object.assign({}, r, { week: new Date(r.week) });
            })
          )
        );
      })
      .catch(err => {
        console.warn(err);
        return dispatch(receiveLatitudes(raptorSpecies, null));
      });
  };
};

// for getting the raptor gps data
const requestRaptorData = raptorSpecies => ({
  type: REQUEST_RAPTOR_DATA,
  raptorSpecies,
  data: null
});

const receiveRaptorData = (raptorSpecies, data) => ({
  type: RECEIVE_RAPTOR_DATA,
  raptorSpecies,
  data
});

export function fetchRaptorData(raptorSpecies) {
  const options = getQueryOptions(raptorSpecies);

  return dispatch => {
    dispatch(requestRaptorData(raptorSpecies));
    return getRaptorData(options)
      .then(({ rows }) => {
        const dataGrouped = groupDataByIdAndDate(rows);
        dispatch(receiveRaptorData(raptorSpecies, dataGrouped));
      })
      .catch(err => {
        console.warn(err);
        return dispatch(receiveRaptorData(raptorSpecies, null));
      });
  };
}

// for getting the osprey names & descriptions
const requestRaptorNames = raptorSpecies => ({
  type: REQUEST_RAPTOR_NAMES,
  raptorSpecies,
  names: null
});

const receiveRaptorNames = (names, raptorSpecies) => ({
  type: RECEIVE_RAPTOR_NAMES,
  raptorSpecies,
  names
});

export const fetchRaptorNames = raptorSpecies => {
  const options = getQueryOptions(raptorSpecies);
  return dispatch => {
    dispatch(requestRaptorNames(raptorSpecies));

    return getRaptorNames(options)
      .then(({ rows }) => {
        // add a "selected" property that determines if each bird has been
        // selected from the UI checkbox
        rows.forEach((el, i) => {
          el.selected = el.active;

          const color = birdColors[i % birdColors.length];
          el.min_color = color.min;
          el.max_color = color.max;
          el.color = color;
        });

        dispatch(receiveRaptorNames(rows, raptorSpecies));
      })
      .catch(err => {
        console.warn(err);
        return dispatch(receiveRaptorNames(null));
      });
  };
};

// for getting the max year_week value to set the slider UI's range
// taken care of above, only receiveMaxDate is used
export function requestMinMaxDates(raptorSpecies) {
  return {
    type: REQUEST_MIN_MAX_DATES,
    raptorSpecies,
    maxDate: null
  };
}

export function receiveMinMaxDates(minMaxDates, raptorSpecies) {
  return {
    type: RECEIVE_MIN_MAX_DATES,
    raptorSpecies,
    minMaxDates
  };
}

export function fetchMinMaxDates(raptorSpecies) {
  const options = getQueryOptions(raptorSpecies);

  return dispatch => {
    dispatch(requestMinMaxDates());

    return getMinMaxDates(options)
      .then(results => {
        results = results.rows;

        // minimum one year timeline
        let minMaxDates = results[0];
        const yearAgo = minMaxDates.maxdate - 1000 * 60 * 60 * 24 * 30 * 12;
        if (minMaxDates.mindate > yearAgo) {
          minMaxDates.mindate = yearAgo;
        }

        dispatch(
          receiveMinMaxDates(
            {
              minDate: minMaxDates.mindate,
              maxDate: minMaxDates.maxdate
            },
            raptorSpecies
          )
        );
      })
      .catch(err => {
        console.error(err);
        return dispatch(receiveMinMaxDates(raptorSpecies, null));
      });
  };
}

// for grabbing the Osprey's nest locations from CartoDB
export function requestNestLocations() {
  return {
    type: REQUEST_NEST_LOCATIONS,
    nests: null
  };
}

export function receiveNestLocations(nests) {
  return {
    type: RECEIVE_NEST_LOCATIONS,
    nests
  };
}

export function fetchNestLocations() {
  return dispatch => {
    dispatch(requestNestLocations());

    return getNestLocations()
      .then(results => dispatch(receiveNestLocations(results.rows)))
      .catch(err => {
        console.error(err);
        return dispatch(receiveNestLocations(null));
      });
  };
}

// for grabbing the Osprey's nest locations from CartoDB
export function requestRanchBoundary() {
  return {
    type: REQUEST_RANCH_BOUNDARY,
    nests: null
  };
}

export function receiveRanchBoundary(ranchBoundary) {
  return {
    type: RECEIVE_RANCH_BOUNDARY,
    ranchBoundary
  };
}

export function fetchRanchBoundary() {
  return dispatch => {
    dispatch(requestRanchBoundary());

    return getRanchBoundary()
      .then(results => dispatch(receiveRanchBoundary(results)))
      .catch(err => {
        console.error(err);
        return dispatch(receiveRanchBoundary(null));
      });
  };
}

const requestKeyAuthentication = () => ({
  type: KEY_AUTHENTICATION_DATA_REQUEST
});

const receiveKeyAuthenticationData = (roles, key) => ({
  type: KEY_AUTHENTICATION_DATA_RESPONSE,
  match: true,
  key,
  roles
});

const receiveKeyAuthenticationError = error => ({
  type: KEY_AUTHENTICATION_DATA_ERROR_RESPONSE,
  error
});

export const fetchKeyAuthentication = key => {
  return dispatch => {
    dispatch(requestKeyAuthentication());
    return fetch(config.check_key_url, {
      method: "POST",
      body: JSON.stringify({ key }),
      headers: new Headers({ "Content-Type": "application/json" })
    })
      .then(response => response.json())
      .then(json => dispatch(receiveKeyAuthenticationData(json.roles, key)))
      .catch(error => dispatch(receiveKeyAuthenticationError(error.message)));
  };
};

const requestPasswordCheck = () => ({
  type: PASSWORD_CHECK_DATA_REQUEST
});

const receivePasswordCheckData = match => ({
  type: PASSWORD_CHECK_DATA_RESPONSE,
  match
});

const receivePasswordCheckError = error => ({
  type: PASSWORD_CHECK_DATA_ERROR_RESPONSE,
  error
});

export const fetchPasswordCheck = (key, password) => {
  const site = "Raptor Map";
  return dispatch => {
    dispatch(requestPasswordCheck());
    return fetch(config.check_password_url, {
      method: "POST",
      body: JSON.stringify({ key, password, site }),
      headers: new Headers({ "Content-Type": "application/json" })
    })
      .then(response => response.json())
      .then(json => dispatch(receivePasswordCheckData(json.success)))
      .catch(error => dispatch(receivePasswordCheckError(error.message)));
  };
};

const requestDisableBird = (birdType, name) => ({
  type: BIRD_DISABLE,
  birdType,
  name
});

const receiveDisableBirdResponse = ({ response, birdType, name }) => ({
  type: BIRD_DISABLE_RESPONSE,
  response,
  birdType,
  name
});

const receiveDisableBirdError = error => ({
  type: BIRD_DISABLE_ERROR_RESPONSE,
  error
});

export const initiateDisableBird = (key, birdType, name) => {
  return dispatch => {
    dispatch(requestDisableBird(birdType, name));
    disableBird(key, birdType, name, (error, json) => {
      if (error) {
        console.warn("Error while disabling bird:", json);
        dispatch(receiveDisableBirdError(error));
      } else {
        dispatch(
          receiveDisableBirdResponse({ response: json, birdType, name })
        );
      }
    });
  };
};

const requestSubmitBird = bird => ({
  type: SUBMIT_BIRD,
  bird
});

const receiveSubmitBirdResponse = ({ response, bird }) => ({
  type: SUBMIT_BIRD_RESPONSE,
  response,
  bird
});

const receiveSubmitBirdError = error => ({
  type: SUBMIT_BIRD_ERROR_RESPONSE,
  error
});

export const initiateSubmitBird = (key, bird) => {
  return dispatch => {
    dispatch(requestSubmitBird(bird));
    submitBird(key, bird, (error, json) => {
      if (error) {
        console.warn("Error while submitting bird:", json);
        dispatch(receiveSubmitBirdError(error));
      } else {
        dispatch(receiveSubmitBirdResponse({ response: json, bird }));
      }
    });
  };
};
