export const user = "mpgranch";
export const table_movebank_data = "movebank_output_combined";
export const table_names = "raptor_names_combined";

export const osprey_nest_table = "osprey_nests_locations";
export const sql_endpoint = `https://${user}.cartodb.com/api/v2/sql?`;
export const backend_url = "https://mpg-habitat-report-builder.herokuapp.com";
export const check_key_url = backend_url + "/api/keys/check";
export const check_password_url = backend_url + "/api/passwords/check";
export const disable_bird_url = backend_url + "/api/raptor/delete";
export const submit_bird_url = backend_url + "/api/raptor/add-update";

export const live_data_map_url = "http://livemap.mpgranch.com/#/";
