import * as asyncActions from "../actions/async_actions.js";

export default (state = {}, action) => {
  switch (action.type) {
    case asyncActions.REQUEST_NEST_LOCATIONS:
      return {
        ...state,
        fetchingNests: true,
        nests: []
      };

    case asyncActions.RECEIVE_NEST_LOCATIONS:
      return {
        ...state,
        fetchingNests: false,
        nests: action.nests
      };

    case asyncActions.REQUEST_RANCH_BOUNDARY:
      return {
        ...state,
        fetchingRanchBoundary: true,
        ranchBoundary: {}
      };

    case asyncActions.RECEIVE_RANCH_BOUNDARY:
      return {
        ...state,
        fetchingRanchBoundary: false,
        ranchBoundary: action.ranchBoundary
      };
    default:
      break;
  }

  return state;
};
