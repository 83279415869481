import { UPDATE_SELECTED_DATE } from "../actions/slider_actions.js";

export default function(state = 0, action) {
  switch (action.type) {
    case UPDATE_SELECTED_DATE:
      return action.timestamp;
    default:
      break;
  }

  return state;
}
