import { DESELECT_BIRD } from "../actions/bird_actions";
import * as actions from "../actions/map_actions.js";

export default function(state = {}, action) {
  switch (action.type) {
    case actions.MAP_MOVED:
      return Object.assign({}, state, action.value);

    case actions.ZOOM_TO:
      return {
        ...state,
        zoomTo: action.mapObj
      };

    case actions.CLEAR_ZOOM_TO:
      return {
        ...state,
        zoomTo: null
      };

    case actions.BASEMAP_TOGGLED:
      return {
        ...state,
        basemap: action.basemap
      };

    case actions.BIRD_ICON_CLICKED:
      return {
        ...state,
        clickedBirdId: action.birdId
      };

    case actions.BIRD_ICON_CLOSED:
      return {
        ...state,
        clickedBirdId: null
      };

    case DESELECT_BIRD:
      return {
        ...state,
        clickedBirdId:
          state.clickedBirdId === action.bird.id ? null : state.clickedBirdId
      };

    default:
      break;
  }

  return state;
}
