export const MAP_MOVED = "MAP_MOVED";
export const ZOOM_TO = "ZOOM_TO";
export const CLEAR_ZOOM_TO = "CLEAR_ZOOM_TO";
export const BASEMAP_TOGGLED = "BASEMAP_TOGGLED";
export const BIRD_ICON_CLICKED = "BIRD_ICON_CLICKED";
export const BIRD_ICON_CLOSED = "BIRD_ICON_CLOSED";

// tracking map state, eg on moveend
export function mapMoved(mapState) {
  return {
    type: MAP_MOVED,
    value: mapState
  };
}

export function zoomTo(mapObj) {
  return {
    type: ZOOM_TO,
    mapObj
  };
}

export function clearZoomTo() {
  return {
    type: CLEAR_ZOOM_TO,
    mapObj: null
  };
}

export function basemapToggled(basemap) {
  return {
    type: BASEMAP_TOGGLED,
    basemap
  };
}

export function birdIconClicked(birdId) {
  return {
    type: BIRD_ICON_CLICKED,
    birdId
  };
}

export function birdIconClosed() {
  return {
    type: BIRD_ICON_CLOSED
  };
}
