import d3 from "d3";

// d3 slider code credit: http://bl.ocks.org/zanarmstrong/ddff7cd0b1220bc68a58

const latitudeToCity = {
  19: "Mexico City",
  34: "Los Angeles",
  46: "MPG",
  61: "Anchorage"
};

function D3Slider() {
  const _public = {};

  const dateFormatString = "%B %d, %Y"; // could have minutes: "%b-%d-%Y-%H:00"
  const dateFormatSQLString = "%Y-%m-%d";
  const formatDate = d3.time.format(dateFormatString);
  const formatDateTicks = d3.time.format("%Y");
  const formatDateSQL = d3.time.format(dateFormatSQLString);
  let handleTextOffsetY = 0;
  let handleTextOffsetX = -18;
  let handlePathD = "M 0 -40 V 50";

  let sliderContainer;
  let containerWidth;
  let margin;
  let rawSize = { width: 700, height: 150 };
  let width;
  let height;

  let svg;
  let wrapper;
  let gx; // svg x-axis group
  let domain;
  let xAxis;
  let slider;
  let handle;
  let brush;
  let brushed;
  let updateStateDate;
  let id = null; // for setting & canceling setInterval / animation
  let curX; // for brush current X position
  let brushValue;
  let textWidth;

  let minDate;
  let maxDate;
  let startDate;
  let timeScale;
  let startingValue;

  let selectedRange;
  let dateBoundStart;
  let dateBoundEnd;
  let updateBounds;

  let gantLines;
  let curRaptorSpecies;
  let curRaptors;
  let raptorLatitudes;

  function setMargins() {
    if (!containerWidth || containerWidth > 767) {
      margin = { top: 15, right: 20, bottom: 20, left: 70 };
    } else if (containerWidth < 767) {
      margin = { top: 20, right: 10, bottom: 20, left: 0 };
      handleTextOffsetX = 5;
      handlePathD = "M 0 -28 V 30";
    }

    width = rawSize.width - margin.right - margin.left;
    height = rawSize.height - margin.top - margin.bottom;
  }

  function setMinMaxDates() {
    if (!minDate) minDate = new Date("2012-05-21");
    if (!maxDate) maxDate = new Date("2017-01-02");
  }

  function setStartDate() {
    // start date for handle's initial position, eg: could come from window hash
    startDate = maxDate ? new Date(maxDate) : new Date("2015-06-20");
  }

  function setBounds() {
    // start date for timeline bounds
    dateBoundEnd = maxDate ? new Date(maxDate) : new Date("2015-06-20");
    dateBoundStart = new Date(
      dateBoundEnd.valueOf() - 1000 * 60 * 60 * 24 * 30 * 6
    );
  }

  function setTimeScale() {
    timeScale = d3.time
      .scale()
      .domain([minDate, maxDate])
      .range([0, width])
      .clamp(true);
  }

  function setHandleStartValue() {
    // initial px value for handle position
    startingValue = startDate;
  }

  function adjustBrushTextPosition() {
    // adjust the text if the handle is close to the left or right side of the slider
    textWidth = handle
      .select("text")
      .node()
      .getBBox().width;
    if (timeScale(brushValue) > width - 75) {
      handle
        .select("text")
        .attr(
          "transform",
          "translate(" +
            (-textWidth - handleTextOffsetX) +
            " ," +
            handleTextOffsetY +
            ")"
        );
    } else if (timeScale(brushValue) < 50) {
      handle
        .select("text")
        .attr(
          "transform",
          "translate(" + handleTextOffsetX + " ," + handleTextOffsetY + ")"
        );
    } else {
      handle
        .select("text")
        .attr(
          "transform",
          "translate(" +
            -(textWidth / 2 + handleTextOffsetX) +
            " ," +
            handleTextOffsetY +
            ")"
        );
    }
  }

  function initBrush() {
    // updates handle position
    brushed = function() {
      // here brushValue is a date
      const extent = brush.extent();
      brushValue = extent[0];

      if (d3.event && d3.event.sourceEvent) {
        // not a programmatic event
        // here brushValue is a position on the slider x axis
        brushValue = timeScale.invert(d3.mouse(this)[0]);
      }

      handle.attr("transform", "translate(" + timeScale(brushValue) + ",0)");
      handle.select("text").text(formatDate(brushValue));

      adjustBrushTextPosition();

      if (extent[0] !== extent[1]) {
        dateBoundStart = extent[0];
        dateBoundEnd = extent[1];
      }

      selectedRange
        .select("rect")
        .attr("y", 10)
        .attr("height", 80)
        .attr("x", timeScale(dateBoundStart))
        .attr("width", timeScale(dateBoundEnd) - timeScale(dateBoundStart))
        .style("fill-opacity", 0.1);

      // pass formatted date string to application state
      updateBounds(formatDateSQL(dateBoundStart), formatDateSQL(dateBoundEnd));
      updateStateDate(formatDateSQL(brushValue));
    };

    // defines brush
    brush = d3.svg
      .brush()
      .x(timeScale)
      .extent([startingValue, startingValue])
      .on("brush", brushed);
  }

  function initSlider() {
    svg = d3
      .select(sliderContainer)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    wrapper = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr("class", "wrapper");

    gantLines = wrapper
      .append("g")
      .attr("class", "gant-lines")
      .attr("height", height - 20)
      .attr("transform", "translate(0, 20)");

    xAxis = d3.svg
      .axis()
      .scale(timeScale)
      .orient("bottom")
      .tickFormat(function(d) {
        return formatDateTicks(d);
      })
      .tickSize(10, 0)
      .tickPadding(5)
      .ticks(d3.time.years);

    gx = wrapper
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    domain = gx.select(".domain");

    domain
      .select(function() {
        return this.parentNode.appendChild(this.cloneNode(true));
      })
      .attr("class", "halo");

    slider = wrapper
      .append("g")
      .attr("class", "slider")
      .call(brush);

    slider.selectAll(".extent,.resize").remove();

    slider
      .select(".background")
      .attr("height", height)
      .style("cursor", "auto");

    handle = slider.append("g").attr("class", "handle");

    handle
      .append("path")
      .attr("transform", "translate(0," + height / 2 + ")")
      .attr("d", handlePathD);

    handle
      .append("text")
      .text(startingValue)
      .attr(
        "transform",
        "translate(" + handleTextOffsetX + " ," + handleTextOffsetY + ")"
      );

    selectedRange = wrapper.append("g").attr("class", "range");

    selectedRange
      .append("rect")
      .attr("y", 10)
      .attr("height", 80)
      .attr("x", timeScale(dateBoundStart))
      .attr("width", timeScale(dateBoundEnd) - timeScale(dateBoundStart))
      .style("fill-opacity", 0.1)
      .on("click", () => {
        // Handle click inside selected range
        moveHandle(d3.mouse(d3.event.target)[0]);
      });

    slider.call(brush.event);
  }

  function resizeSlider(widthHeight) {
    width = widthHeight.width - margin.left - margin.right;
    height = widthHeight.height - margin.top - margin.bottom;

    d3.selectAll(".slider-container svg").remove();

    curX = d3.transform(handle.attr("transform")).translate[0];

    setStartDate(timeScale.invert(curX));
    setBounds();
    setTimeScale();
    setHandleStartValue();
    brush.x(timeScale);
    initSlider();
    if (raptorLatitudes && curRaptors && curRaptorSpecies) drawLinesTogether();
  }

  function animate(animating) {
    const interval = 150;
    // is the actual animation happening
    let isAnimating = false;
    // initial position of the handle
    curX = d3.transform(handle.attr("transform")).translate[0];

    // plays during setInterval loop
    function frame() {
      brushValue = new Date(
        timeScale.invert(curX).valueOf() + 1000 * 60 * 60 * 24
      );

      if (brushValue > dateBoundEnd) {
        brushValue = dateBoundStart;
      }

      curX = timeScale(brushValue);
      moveHandle(curX);
    }

    if (animating && !isAnimating) {
      // start animation
      id = setInterval(frame, interval);
      isAnimating = true;
    } else {
      // end animation
      clearInterval(id);
      isAnimating = false;
    }
  }

  function moveHandle(x) {
    const date = timeScale.invert(x);
    handle.attr("transform", `translate(${x}, 0)`);
    handle.select("text").text(formatDate(date));
    updateStateDate(formatDateSQL(date));
    adjustBrushTextPosition();
  }

  function drawLinesTogether() {
    const heightPadding = 5;
    const availableHeight = gantLines.attr("height");

    let data = d3
      .nest()
      .key(d => d.id)
      .entries(raptorLatitudes);

    const selectedRaptors = curRaptors.filter(raptor => raptor.selected);

    data = selectedRaptors.map(raptor => {
      const raptorData = data.filter(d => d.key === raptor.id)[0];
      return Object.assign({}, raptorData, {
        color: raptor.max_color,
        name: raptor.name
      });
    });

    const values = raptorLatitudes.map(d => d.latitude);
    const yExtent = [d3.min(values), d3.max(values)];
    const yScales = {};
    const rootYScale = d3.scale
      .linear()
      .domain(yExtent)
      .range([availableHeight - heightPadding, heightPadding]);

    data.forEach((group, i) => {
      if (group.values) {
        yScales[group.key] = d3.scale
          .linear()
          .domain(yExtent)
          .range([availableHeight - heightPadding - i, heightPadding - i]);
      }
    });

    const yAxis = d3.svg
      .axis()
      .scale(rootYScale)
      .orient("left")
      .tickSize(10, 0)
      .tickPadding(5)
      .tickValues(
        Object.keys(latitudeToCity).filter(
          lat => lat >= yExtent[0] && lat <= yExtent[1]
        )
      )
      .tickFormat(d => latitudeToCity[d]);

    wrapper.selectAll(".y.axis").remove();

    wrapper
      .append("g")
      .classed("y axis", true)
      .attr("transform", `translate(8, ${margin.top + heightPadding})`)
      .call(yAxis);

    const line = d3.svg
      .line()
      .x(d => timeScale(d.week))
      .y(d => yScales[d.id](d.latitude));

    const selection = gantLines.selectAll(".gant").data(data, d => d.key);

    selection.exit().remove();

    selection
      .enter()
      .append("path")
      .attr("class", d => `gant-${d.name.toLowerCase()}`)
      .classed("gant", true)
      .attr("fill", "none")
      .attr("stroke-width", 2)
      .attr("stroke", d => d.color);

    selection
      .transition()
      .duration(200)
      .attr("d", d => (d.values ? line(d.values) : null));
  }

  function init() {
    setMinMaxDates(null);
    setStartDate(null);
    setBounds(null);
    setTimeScale();
    setHandleStartValue();
    initBrush();
    initSlider();
  }

  _public.animate = animating => {
    // handles the animation of the slider
    if (animating === "undefined" || animating === null) {
      console.warn("needs an animation state");
      return false;
    }

    animate(animating);

    return _public;
  };

  _public.updateState = (...args) => {
    if (!args.length) return;

    if (typeof args[0] === "function") {
      updateStateDate = args[0];
    }

    return _public;
  };

  _public.updateBounds = (...args) => {
    if (!args.length) return;

    if (typeof args[0] === "function") {
      updateBounds = args[0];
    }

    return _public;
  };

  _public.updateSliderProps = (...args) => {
    if (!args.length) return;

    curX = d3.transform(handle.attr("transform")).translate[0];

    return _public;
  };

  _public.setMinMaxDates = (...args) => {
    if (!args.length) return false;

    maxDate = args[0].maxDate;
    minDate = args[0].minDate;

    return _public;
  };

  _public.setLatitudes = (raptorSpecies, birds, latitudes) => {
    if (!birds.length || !latitudes) return false;
    curRaptorSpecies = raptorSpecies;
    curRaptors = birds;
    raptorLatitudes = latitudes;
    drawLinesTogether();
  };

  _public.resizeSlider = (...args) => {
    if (!args.length) return false;

    const newWidthHeight = args[0];
    resizeSlider(newWidthHeight);

    return _public;
  };

  _public.setContainerWidth = width => {
    if (!width || typeof width !== "number") {
      return false;
    }

    containerWidth = width;
    setMargins();

    return _public;
  };

  _public.mount = (selector, widthHeight) => {
    if (!selector) {
      console.error("no DOM selector supplied for d3 slider");
      return false;
    } else if (!widthHeight) {
      console.error("no widthHeight provided");
      return false;
    }

    sliderContainer = selector;
    width = widthHeight.width - margin.right - margin.left;
    height = widthHeight.height - margin.top - margin.bottom;

    init();

    return _public;
  };

  return _public;
}

export default D3Slider;
