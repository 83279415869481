import React from "react";
import { withRouter } from "react-router";
import classNames from 'classnames';

import { raptorTypes } from '../constants/app';
import RaptorLink from "./RaptorLink.jsx";

const SelectRaptorSpecies = ({ browser, tab, raptor }) => {
  return (
    <div className={classNames('ui-box', {
      'select-raptor-species' : (!browser.greaterThan.small && tab === 'raptors') ||
        browser.greaterThan.small,
      'hidden': !browser.greaterThan.small && (tab !== 'raptors')
    })}>
      <ul>
        {raptorTypes.map(type => (
          <li key={type} className={raptor === type ? 'active': ''}>
            <RaptorLink raptor={type}>
              <span className={classNames('link-icon', `link-icon-${type}`)}>
              </span>
            </RaptorLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default withRouter(SelectRaptorSpecies);
