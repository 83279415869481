import Mustache from "mustache";

import { singleLineString } from "../constants/app.js";
import * as config from "../constants/config.js";

export const fetchSql = (sql, format, options = {}) => {
  const encodedSql = singleLineString([Mustache.render(sql, options)]);
  const url = `${config.sql_endpoint}format=${format}&q=${encodedSql}`;
  return fetch(url).then(response => response.json());
};
