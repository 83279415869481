import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import d3 from "d3";

import { isEqual, isEmpty } from "lodash";

import {
  mapMoved,
  birdIconClicked,
  birdIconClosed
} from "../../actions/map_actions.js";
import {
  fetchRaptorData,
  fetchNestLocations,
  fetchRanchBoundary
} from "../../actions/async_actions.js";
import { clearZoomTo } from "../../actions/map_actions.js";
import { updateSelectedDate } from "../../actions/slider_actions.js";

import {
  getSelectedRaptorSpeciesState,
  initialMapView
} from "../../constants/app.js";

import BirdProfile from "../BirdProfile.jsx";
import LMap from "./lMap.js";

class LeafletMap extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // flags for async actions
    this.firstPageInit = true;
    this.iconsCreated = false;
    // this.nestsStored = false;
    // this.ranchBoundaryStored = false;

    this.getId = this.getId.bind(this);
    this.initMap = this.initMap.bind(this);
    this.makeIcons = this.makeIcons.bind(this);
    this.updateBirdPositions = this.updateBirdPositions.bind(this);
    this.setNestsLocations = this.setNestsLocations.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.zoomWorld = this.zoomWorld.bind(this);
    this.toggleBaseMap = this.toggleBaseMap.bind(this);
  }

  componentWillMount() {
    // set up initial state
    let basemap = this.props.map.basemap || "satellite";
    this.setState({
      map: null,
      ranchBoundaryStored: false,
      nestsStored: false,
      basemap: {
        positron: basemap === "positron",
        satellite: basemap === "satellite"
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { map } = this.state;
    const { mpgRanch, raptor, raptorState, date, timelineBounds } = nextProps;
    const { birds, data, minMaxDates, startEndDates } = raptorState;

    if (map) {
      if (raptor !== this.props.raptor) {
        // remove previous raptors from map
        this.clearBirds(map);

        // reset flags on raptor species toggle
        this.firstPageInit = true;
        this.iconsCreated = false;

        // fetch data for other raptors if not already in store
        if (isEmpty(raptorState.data)) this.props.fetchRaptorData(raptor);
      }

      // make icons for the current selected raptor species
      if (raptorState.birds.length && !this.iconsCreated) {
        this.makeIcons(map, raptorState.birds, raptor);
      }

      // create the osprey nests, should only happen once on app load
      if (mpgRanch.nests.length && !this.state.nestsStored) {
        this.setNestsLocations(map, mpgRanch.nests);
      }

      // create the ranch boundary, should only happen once on app load
      if (
        mpgRanch.ranchBoundary.features &&
        map &&
        !this.state.ranchBoundaryStored
      ) {
        this.setRanchBoundary(map, mpgRanch.ranchBoundary);
      }

      // when the app first loads or raptor species changes
      if (
        !isEmpty(data) &&
        !isEmpty(minMaxDates) &&
        this.iconsCreated &&
        startEndDates &&
        this.firstPageInit
      ) {
        // pass the max date for the current raptor species to updateBirdPositions,
        // otherwise addBird is recursively called too frequently
        const { maxDate } = minMaxDates;
        const dateFormatSQLString = "%Y-%m-%d";
        const formatDateSQL = d3.time.format(dateFormatSQLString);
        let latestRaptorDate = formatDateSQL(new Date(maxDate));

        this.updateBirdPositions(map, {
          date: latestRaptorDate,
          birds,
          data,
          startEndDates,
          timelineBounds
        });
        this.zoomWorld(raptor);
        this.firstPageInit = false;
      }

      // if the timeslider date changes, update the bird positions
      if (
        !this.firstPageInit &&
        this.iconsCreated &&
        !isEmpty(data) &&
        nextProps.date !== this.props.date
      ) {
        this.updateBirdPositions(map, {
          date,
          birds,
          data,
          startEndDates,
          timelineBounds
        });
      }

      // check to see if a user toggled a bird
      if (
        !isEmpty(data) &&
        this.iconsCreated &&
        !isEqual(birds, this.props.raptorState.birds) &&
        raptor === this.props.raptor
      ) {
        let birdLocationsShouldUpdate = false;

        birds.forEach(nextBird => {
          this.props.raptorState.birds.forEach(bird => {
            if (
              nextBird.id === bird.id &&
              nextBird.selected !== bird.selected
            ) {
              birdLocationsShouldUpdate = true;
            }
          });
        });

        if (birdLocationsShouldUpdate) {
          this.updateBirdPositions(map, {
            date,
            birds,
            data,
            startEndDates,
            timelineBounds
          });
          this.zoomWorld(raptor);
        }
      }

      // handles zooming & centering leaflet map on a bird or osprey nest when user clicks locator button
      if (
        nextProps.map.zoomTo &&
        nextProps.map.zoomTo !== this.props.map.zoomTo
      ) {
        const zoomTo = nextProps.map.zoomTo;
        // zoomTo as a string or zoomTo > 3 means a raptor id such as 117181
        if (typeof zoomTo === "string" || zoomTo > 3) {
          this.zoomToBird(map, zoomTo);
        } else if (zoomTo < 4 && zoomTo > 0) {
          // zoomTo < 4 means a nest icon id of 1, 2, or 3
          this.zoomToNest(map, nextProps.map.zoomTo);
        }
      }
    }
  }

  componentDidMount() {
    const { raptor } = this.props;
    this.initMap();
    this.props.fetchRaptorData(raptor);
    this.props.fetchNestLocations();
    this.props.fetchRanchBoundary();
  }

  componentWillUnmount() {
    this.state.map.remove();
    this.setState({
      map: null,
      basemap: {
        positron: true,
        satellite: false
      }
    });
  }

  getId() {
    return ReactDOM.findDOMNode(this.refs.leafletMap).id;
  }

  initMap() {
    const that = this;
    const map = new LMap();
    let mapView = initialMapView;
    let defaultBasemap = this.state.basemap.positron ? "positron" : "satellite";
    function onMapMovedAugmented(...args) {
      that.props.mapMoved(...args);
      map.spacifyLabels();
    }
    map.setContainerWidth(this.props.browser.width);
    map.setDefaultBaseMap(defaultBasemap);
    map.setInitialView([mapView.center, mapView.zoom]);
    map.setBirdIconClick(this.props.birdIconClicked);
    map.mount(ReactDOM.findDOMNode(this.refs.leafletMap).id);
    map.onMoveEnd(onMapMovedAugmented);
    this.setState({ map });
  }

  makeIcons(map, birds, raptor) {
    map.makeIcons(birds, raptor);
    this.iconsCreated = true;
  }

  setDefaultBaseMap(map, basemap) {
    map.setDefaultBaseMap(basemap);
  }

  setNestsLocations(map, nests) {
    map.setNestsLocations(nests);
    this.setState({ nestsStored: true });
  }

  setRanchBoundary(map, geojson) {
    map.setRanchBoundary(geojson);
    this.setState({ ranchBoundaryStored: true });
  }

  updateBirdPositions(map, nextProps) {
    map.updateBirdPositions(nextProps);
  }

  zoomToBird(map, birdId) {
    map.zoomToBird(birdId);
    this.props.clearZoomTo();
  }

  zoomToNest(map, nestId) {
    map.zoomToNest(nestId);
    this.props.clearZoomTo();
  }

  zoomIn(map) {
    if (!this.state.map) return false;
    this.state.map.zoomIn();
  }

  zoomOut(map) {
    if (!this.state.map) return false;
    this.state.map.zoomOut();
  }

  zoomWorld(raptorSpecies) {
    if (!this.state.map) return false;
    this.state.map.zoomWorld(raptorSpecies);
  }

  toggleBaseMap() {
    this.setState({
      ...this.state,
      basemap: {
        positron: !this.state.basemap.positron,
        satellite: !this.state.basemap.satellite
      }
    });

    this.state.map.toggleBaseMap(this.state.basemap);
  }

  clearBirds(map) {
    if (!this.state.map) return false;
    map.clearBirds();
  }

  render() {
    const { browser } = this.props;
    const basemapToggleStyle = this.state.basemap.satellite
      ? "positron"
      : "satellite";

    // initial className for mobile
    const className =
      (this.props.selectedTab === "map" && !browser.greaterThan.extraSmall) ||
      (this.props.selectedTab === "map" && !browser.greaterThan.small) ||
      browser.greaterThan.small
        ? "map-container"
        : "map-container hidden";

    return (
      <div className={className}>
        <div className="map-zoom-btns map-ui">
          <ul>
            <li className="zoom-in" onClick={this.zoomIn} />
            <li className="zoom-out" onClick={this.zoomOut} />
            <li className="zoom-world" onClick={this.zoomWorld} />
          </ul>
        </div>
        <div className="basemap-toggle map-ui" onClick={this.toggleBaseMap}>
          <div
            className={classNames("basemap-toggle-button", basemapToggleStyle)}
          />
          <div className="basemap-toggle-label">change basemap</div>
        </div>
        {browser.greaterThan.small ? (
          <BirdProfile raptor={this.props.raptor} />
        ) : null}
        <div id="map" ref="leafletMap" />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      mapMoved,
      fetchRaptorData,
      fetchNestLocations,
      fetchRanchBoundary,
      clearZoomTo,
      birdIconClicked,
      birdIconClosed,
      updateSelectedDate
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ...state,
    raptorState: getSelectedRaptorSpeciesState(state.raptors, ownProps.raptor),
    raptor: ownProps.raptor
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeafletMap);
