import { combineReducers } from "redux";
import { createResponsiveStateReducer } from "redux-responsive";

import RaptorsReducer from "./raptors_reducer.js";
import MPGRanchReducer from "./mpg_ranch_reducer.js";
import SelectedDateReducer from "./selected_date_reducer.js";
import AnimationReducer from "./animation_reducer.js";
import MapReducer from "./map_reducer.js";
import MobileNavReducer from "./mobile_nav_reducer.js";
import AuthenticationReducer from "./authentication_reducer";
import BoundsReducer from "./bounds_reducer";

import { breakpoints } from "../scss/variables.json";

// custom breakpoints for redux-responsive store
const browser = createResponsiveStateReducer({
  extraSmall: breakpoints.width.xsmall,
  small: breakpoints.width.small,
  medium: breakpoints.width.medium,
  large: breakpoints.width.large
});

const rootReducer = combineReducers({
  animating: AnimationReducer,
  browser,
  date: SelectedDateReducer,
  map: MapReducer,
  mpgRanch: MPGRanchReducer,
  raptors: RaptorsReducer,
  selectedTab: MobileNavReducer,
  authentication: AuthenticationReducer,
  timelineBounds: BoundsReducer
});

export default rootReducer;
