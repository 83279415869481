/* global L */
// import MiniMap from 'leaflet-minimap';
import centroid from "turf-centroid";
import d3 from "d3";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";

import {
  birdHasDataNearDate,
  initialMapView,
  idToName,
  idToGradient
} from "../../constants/app.js";
import * as inits from "./initMap";

function LMap() {
  const _public = {};

  let init;
  let map;
  let raptorData; // current selected raptor location data
  let curRaptor; // current selected raptor species
  let nestData;
  let curDate;
  let startDate;
  let endDate;
  let ranchBoundary;
  let ranchLabel;
  let curBirdLocations; // icon positions
  let prevBirdLocations; // trail lines
  let prevBirdMarkers;
  let nestLocations;
  let lastKnownLatLons = {};
  let lastProps;

  /*
   * Map helper functions
   */

  function didZoom() {
    const zoom = map.getZoom();
    if (zoom === 9 || zoom === 10) {
      let renderer = L.canvas();

      prevBirdMarkers.options.pointToLayer = (feature, latlng) => {
        return L.circleMarker(latlng, {
          renderer,
          radius: zoom === 10 ? 8 : 4,
          color: "black",
          weight: 1,
          fillColor: feature.properties.color,
          fillOpacity: 0.3,
          opacity: 0.5
        });
      };
    }

    if (zoom >= 12 && curRaptor === "ospreys") {
      nestLocations.eachLayer(layer => {
        layer.openTooltip();
        layer.setOpacity(1);
      });
    } else {
      nestLocations.eachLayer(layer => {
        layer.closeTooltip();
        layer.setOpacity(0);
      });
    }

    _public.updateBirdPositions(lastProps);
  }

  function setMapView(latLon, zoom) {
    if (!latLon) return false;
    if (latLon && latLon.length) map.setView(latLon, zoom);
  }

  function getLatLon(id, dateString) {
    if (!dateString) dateString = curDate;

    let latLon = [];

    let obj = raptorData[id];

    if (obj && obj[dateString]) {
      // if there's a date string match on formatted_date
      latLon[0] = obj[dateString][0].lat;
      latLon[1] = obj[dateString][0].lon;
    }

    return latLon;
  }

  function getNestLatLon(id) {
    let latLon = [];

    nestData.forEach(nest => {
      if (nest.cartodb_id === id) {
        latLon.push(nest.lat);
        latLon.push(nest.lon);
      }
    });

    return latLon;
  }

  function setNestLayer() {
    nestData.forEach(nest => {
      const latLon = [nest.lat, nest.lon];
      const marker = L.circleMarker(latLon).toGeoJSON();
      marker.properties.id = nest.cartodb_id;
      marker.properties.name = nest.name; // to do: add name
      nestLocations.addData(marker);
    });
  }

  function setRanchBoundary(geojson) {
    // create the label for the ranch boundary
    // make a centroid from turf.centroid
    const centerPt = centroid(geojson);
    ranchLabel.addData(centerPt);
    // add the ranch boundaries
    ranchBoundary.addData(geojson);
    ranchBoundary.bringToBack();
  }

  function intersectRect(r1, r2) {
    return !(
      r2.left > r1.right ||
      r2.right < r1.left ||
      r2.top > r1.bottom ||
      r2.bottom < r1.top
    );
  }

  function unTouch(item, items) {
    if (item.touched) return;

    items.forEach(d => {
      if (d.idx === item.idx || d.touched) return;
      if (intersectRect(item.box, d.box)) {
        d.touched = true;
      }
    });
  }

  function spacifyLabels() {
    if (!curBirdLocations) return;

    const layers = curBirdLocations.getLayers();
    const items = [];

    layers.forEach((lyr, idx) => {
      const tooltip = lyr.getTooltip();
      const container = tooltip._container;
      const pos = { x: container._leaflet_pos.x, y: container._leaflet_pos.y };
      const width = container.offsetWidth;
      const height = container.offsetHeight;

      const box = {
        left: pos.x,
        top: pos.y,
        right: pos.x + width,
        bottom: pos.y + (height - 2)
      };

      items.push({
        idx,
        container,
        pos,
        box,
        zindex: lyr._zIndex,
        touched: false
      });
    });

    // sort labels so lower z-indexes
    // will be removed first
    items.sort((a, b) => {
      return a.zindex - b.zindex;
    });

    items.forEach(item => {
      // collison test
      unTouch(item, items);
    });

    items.forEach(item => {
      item.container.style.opacity = item.touched ? 0 : 1;
    });
  }

  /*
   * For adding GeoJSON osprey data to the Leaflet map
   */
  _public.updateBirdPositions = nextProps => {
    const { date, birds, data, startEndDates, timelineBounds } = nextProps;
    lastProps = nextProps;
    curDate = date;
    startDate = timelineBounds[0];
    endDate = timelineBounds[1];

    if (!birds.length || isEmpty(data) || !date) {
      return false; // shouldn't we return _public;
    }

    if (!raptorData || !isEqual(raptorData, data)) raptorData = data;

    birds.forEach((bird, index) => {
      // if the bird is selected on the UI
      if (!startEndDates[bird.id]) return;
      const current = moment(curDate);

      const hasDataAtThisDate = birdHasDataNearDate(
        bird,
        startEndDates,
        current
      );

      if (bird.selected && hasDataAtThisDate) {
        // if the bird hasn't been added to the map, try to add it or
        // update its position
        if (!birdAdded(bird.id)) {
          addBird(bird.id, birds);
          addPrevLocations(bird.id);
        } else {
          updateLatLon(bird.id);
          updatePrevLocations(bird.id);
        }
      } else {
        // check to see if it has been added, if so
        // then remove it from the geoJson group
        if (birdAdded) {
          removeBird(bird.id);
          removePrevLocations(bird.id);
        }
      }

      spacifyLabels();
    });

    // duplicating idToName?
    function getName(id) {
      return birds
        .filter(bird => {
          return bird.id === id;
        })
        .map(bird => {
          return bird.name;
        })
        .join("");
    }

    function birdAdded(id) {
      let bool = false;

      curBirdLocations.eachLayer(layer => {
        if (layer.feature.properties.id === id) {
          bool = true;
        }
      });

      return bool;
    }

    function addBird(id, birds) {
      // Only add bird if it has start and end dates
      if (!startEndDates[id]) return;
      const lastDate = startEndDates[id].end_date_formatted;
      const firstDate = startEndDates[id].start_date_formatted;
      const fallbackDate = Object.keys(raptorData[id])[0];
      let latLon = [];

      // try to catch cases when slider date doesn't match raptor location date...
      if (curDate < firstDate || curDate > lastDate) {
        latLon = getLatLon(id, fallbackDate, true, lastDate);
      } else {
        latLon = getLatLon(id, null, true, lastDate);
      }

      // try to catch cases when slider date doesn't match raptor location date...
      if (!latLon.length) {
        latLon = getLatLon(id, fallbackDate, true, lastDate);
      }

      let name = getName(id);
      let gradient = idToGradient(birds, id);
      lastKnownLatLons[id] = latLon;

      const marker = L.marker(latLon).toGeoJSON();
      marker.properties.id = id;
      marker.properties.name = name;
      marker.properties.color = gradient.max;
      curBirdLocations.addData(marker);
      curBirdLocations.bringToFront();

      const displayDate = moment(new Date(startEndDates[id].end_date)).format(
        "MMMM Do YYYY, hh:mm:ss UTC"
      );

      // set the color of the label text
      d3.select(`.raptor-icon-label.${name}`)
        .style({
          color: `${gradient.max}`
        })
        .attr("title", displayDate);

      d3.select(`.${name}-icon`).attr("title", displayDate);
    }

    function updateLatLon(id) {
      const lastDate = startEndDates[id].end_date_formatted;
      const firstDate = startEndDates[id].start_date_formatted;

      // if the bird's date isn't within the slider range don't try to update it
      if (curDate < firstDate || curDate > lastDate) return;

      let latLon = getLatLon(id, null, false, lastDate);

      if (!latLon.length) {
        latLon = lastKnownLatLons[id];
      }

      curBirdLocations.eachLayer(layer => {
        if (layer.feature.properties.id === id) {
          layer.setLatLng(latLon);
          lastKnownLatLons[id] = latLon;
        }
      });

      curBirdLocations.bringToFront();
    }

    function removeBird(id) {
      curBirdLocations.eachLayer(layer => {
        if (layer.feature.properties.id === id) {
          curBirdLocations.removeLayer(layer._leaflet_id);
        }
      });
    }

    function getLatLons(id) {
      let latLons = [];
      let obj = raptorData[id];
      if (!obj) return;

      Object.keys(obj).forEach(objDate => {
        if (objDate <= endDate && objDate >= startDate) {
          obj[objDate].forEach(o => {
            latLons.push([o.lat, o.lon]);
          });
        }
      });

      return latLons;
    }

    function addPrevLocations(id) {
      const latLons = getLatLons(id);
      if (!latLons) return;

      const polyline = L.polyline(latLons).toGeoJSON();
      // add id to polyline.properties..
      polyline.properties.id = id;
      // add name to polyline.properties..
      polyline.properties.name = idToName(birds, id);
      // add raptor to polyline.properties...
      polyline.properties.raptorSpecies = curRaptor;
      // add color to polyline.properties...
      polyline.properties.color = idToGradient(birds, id).max;
      prevBirdLocations.addData(polyline);
      prevBirdLocations.bringToFront();

      if (map.getZoom() > 6) {
        let points = { type: "FeatureCollection", features: [] };

        latLons.forEach(ll => {
          points.features.push({
            type: "Feature",
            geometry: { type: "Point", coordinates: [ll[1], ll[0]] },
            properties: {
              color: idToGradient(birds, id).max,
              id
            }
          });
        });

        prevBirdMarkers.addData(points);
        prevBirdMarkers.bringToFront();
      }
    }

    function removePrevLocations(id) {
      prevBirdLocations.eachLayer(layer => {
        if (layer.feature.properties.id === id) {
          prevBirdLocations.removeLayer(layer._leaflet_id);
        }
      });

      prevBirdMarkers.eachLayer(layer => {
        if (layer.feature.properties.id === id) {
          prevBirdMarkers.removeLayer(layer._leaflet_id);
        }
      });
    }

    function updatePrevLocations(id) {
      const latLons = getLatLons(id);
      if (!latLons.length) {
        // removePrevLocations(id);
      }
      prevBirdLocations.eachLayer(layer => {
        if (layer.feature.properties.id === id) {
          layer.setLatLngs(latLons);
        }
      });
      prevBirdLocations.bringToFront();

      if (map.getZoom() > 6) {
        prevBirdMarkers.eachLayer(layer => {
          if (layer.feature.properties.id === id) {
            prevBirdMarkers.removeLayer(layer._leaflet_id);
          }
        });

        let points = { type: "FeatureCollection", features: [] };

        latLons.forEach(ll => {
          points.features.push({
            type: "Feature",
            geometry: { type: "Point", coordinates: [ll[1], ll[0]] },
            properties: {
              color: idToGradient(birds, id).max,
              id
            }
          });
        });

        prevBirdMarkers.addData(points);
        prevBirdMarkers.bringToFront();
      } else {
        prevBirdMarkers.eachLayer(layer => {
          if (layer.feature.properties.id === id) {
            prevBirdMarkers.removeLayer(layer._leaflet_id);
          }
        });
      }
    }

    return _public;
  };

  _public.mount = selector => {
    if (!selector) {
      console.error("LMap needs a selector for map div");
      return _public;
    }
    init = inits.init(selector);
    map = init.map;
    map.on("zoom", didZoom);
    curBirdLocations = init.curBirdLocations;
    prevBirdLocations = init.prevBirdLocations;
    prevBirdMarkers = init.prevBirdMarkers;
    nestLocations = init.nestLocations;
    ranchBoundary = init.ranchBoundary;
    ranchLabel = init.ranchLabel;
    return _public;
  };

  _public.makeIcons = (birds, raptor) => {
    if (!birds || !raptor) {
      console.error("need list of birds & raptor species type to make icons");
      return _public;
    }
    if (birds && birds.length && raptor) {
      inits.makeIcons(birds, raptor);
      curRaptor = raptor;
    }
    return _public;
  };

  _public.setContainerWidth = width => {
    if (!width || typeof width !== "number") {
      return false;
    }
    inits.setContainerWidth(width);
    return _public;
  };

  _public.setInitialView = arr => {
    inits.setInitialView(arr);
    return _public;
  };

  _public.setDefaultBaseMap = basemap => {
    inits.setDefaultBaseMap(basemap);
    return _public;
  };

  _public.setNestsLocations = ospreyNests => {
    if (!ospreyNests) {
      return _public;
    }
    nestData = ospreyNests;
    setNestLayer();
    return _public;
  };

  _public.setRanchBoundary = geojson => {
    if (!geojson) {
      return _public;
    }
    setRanchBoundary(geojson);
    return _public;
  };

  _public.zoomToBird = birdId => {
    if (!birdId) {
      console.error("no birdId for zoomToBird");
      return _public;
    }
    let latLon = getLatLon(birdId);
    // if no latLon for current bird location try using the last known location...
    if (!latLon.length) latLon = lastKnownLatLons[birdId];
    setMapView(latLon, 12);
    return _public;
  };

  _public.zoomToNest = nestId => {
    if (!nestId) {
      console.error("no nestId for zoomToNest");
      return _public;
    }
    const latLon = getNestLatLon(nestId);
    setMapView(latLon, 15);
    return _public;
  };

  _public.zoomIn = () => {
    map.zoomIn();
  };

  _public.zoomOut = () => {
    map.zoomOut();
  };

  _public.zoomWorld = raptorSpecies => {
    let center = curBirdLocations.getLayers().length
      ? curBirdLocations.getBounds().getCenter()
      : initialMapView.center;
    map.setView(center, initialMapView.zoom);
  };

  _public.toggleBaseMap = x => {
    const bmaps = Object.keys(x);
    const toAdd = bmaps.filter(b => {
      return !x[b];
    });
    const toRemove = bmaps.filter(b => {
      return x[b];
    });

    if (Array.isArray(init[toRemove[0]])) {
      init[toRemove[0]].forEach(l => map.removeLayer(l));
    } else {
      map.removeLayer(init[toRemove[0]]);
    }

    if (Array.isArray(init[toAdd[0]])) {
      init[toAdd[0]].forEach(l => map.addLayer(l));
    } else {
      map.addLayer(init[toAdd[0]]);
    }

    return _public;
  };

  _public.onMoveEnd = func => {
    if (func && typeof func === "function") {
      inits.onMoveEnd(func);
    }
    return _public;
  };

  _public.setBirdIconClick = func => {
    if (func && typeof func === "function") {
      inits.setBirdIconClick(func);
    }
    return _public;
  };

  _public.clearBirds = () => {
    curBirdLocations.clearLayers();
    prevBirdLocations.clearLayers();
    prevBirdMarkers.clearLayers();
    return _public;
  };

  _public.spacifyLabels = () => {
    spacifyLabels();
    return _public;
  };

  _public.remove = () => {
    map.remove();
  };

  return _public;
}

export default LMap;
