export const UPDATE_SELECTED_DATE = "UPDATE_SELECTED_DATE";
export const UPDATE_BOUNDS = "UPDATE_BOUNDS";
export const PLAY_ANIMATION = "PLAY_ANIMATION";
export const STOP_ANIMATION = "STOP_ANIMATION";

export function updateSelectedDate(timestamp) {
  return {
    type: UPDATE_SELECTED_DATE,
    timestamp
  };
}

export function updateBounds(start, end) {
  return {
    type: UPDATE_BOUNDS,
    start,
    end
  };
}

export function playAnimation() {
  return {
    type: PLAY_ANIMATION
  };
}

export function stopAnimation() {
  return {
    type: STOP_ANIMATION
  };
}
